import React, { useState, useEffect } from "react";
import { listImg, listByDate, listByRegion } from "../../components/model"
import RenderImg from "../../components/render-img.view";
import GalaryImages from "../../components/gallery/gallery.view";
import SimpleDialog from "../../components/simple-dialog/simple-dialog.view";
import apiConfig from "../../../../api/api-config";

//---Mui Ui
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import IconButton from "@material-ui/core/IconButton";
import HistoryIcon from "@material-ui/icons/History";
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {
    Button,
} from "@material-ui/core";

import { formatAddress } from "../../helper/helper";
import dateformat from "dateformat";
import RemoteSensingHistoryItem from "../../components/form/form-remote-sensing-history.view"
import EditHistory from "./edit.view"

function DetailResult(props) {
    const { dataHistory, itemSelected, reloadData } = props;
    const [historySelected, setHistorySelected] = useState();
    const [gallerySelect, setGallerySelect] = useState([])
    const [isOpenGallary, setIsOpenGallary] = useState(false);
    const [index, setIndex] = useState(0);
    const [isOpenEdit, setIsOpenEdit] = useState(false);


    const HandleOpenGallery = (gallery, index) => {
        setGallerySelect(gallery);
        setIsOpenGallary(true);
        setIndex(index);
    }

    useEffect(() => { console.log(itemSelected) }, [itemSelected])

    const handleOpenEditDialog = (data) => {
        setIsOpenEdit(true)
        setHistorySelected(data)
    }

    return (
        <>
            {itemSelected && (
                <div>
                    <div className="name_address">
                        <div className="name_address_header">
                            <div className="name">{formatAddress(itemSelected)}</div>
                            <div className="coord">{`(13.23456, 108.65432)`}</div>
                        </div>
                        <div className="note_update">
                            cập nhật gần đây nhất lúc {dateformat(itemSelected.modifiedDate, "HH:mm ngày dd/mm/yyyy")}
                        </div>
                    </div>
                    <div className="region_block">
                        {dataHistory.map((item, index) => (
                            <div key={index} className="region_item">
                                <div className="region_header">
                                    <p>
                                        {item.actionName === 'RECEIVE' ? 'Khởi tạo ngày ' : 'Cập nhật ngày '}
                                        {dateformat(item.eventDate, "dd/mm/yyyy") + " - "}
                                        <span>{item.content}</span></p>
                                    <div className="region_header_btn">
                                        <a
                                            className={`button_collapse ${index !== 0 ? 'collapsed' : ''}`}
                                            data-toggle="collapse"
                                            href={`#collapse-${index}`}
                                            role="button"
                                            aria-expanded={`${index === 0 ? 'true' : 'false'}`}
                                            aria-controls={`collapse-${index}`}
                                        >
                                            <IconButton className="arrow_left" aria-label="Thông báo" title="Mở rộng">
                                                <ArrowLeftIcon fontSize="medium" />
                                            </IconButton>
                                            <IconButton className="arrow_down" aria-label="Thông báo" title="Thu gọn">
                                                <ArrowDropDownIcon fontSize="medium" />
                                            </IconButton>
                                        </a>
                                    </div>
                                </div>
                                <div className={`collapse ${index === 0 ? 'show' : ''}`} id={`collapse-${index}`}>
                                    {true && (
                                        <>
                                            <div className="card card-body">
                                                {item.galaryImageFile && item.galaryImageFile.length > 0 && (
                                                    <div className="list_img">
                                                        {item.galaryImageFile.map((img, index) => (
                                                            <div key={index} className="img_item">
                                                                <img src={apiConfig.api + img} />
                                                                <div className="img_item_overlay">
                                                                    <ZoomOutMapIcon onClick={() => HandleOpenGallery(item.galaryImageFile, index)} className="" fontSize="large" />
                                                                    {/* <HistoryIcon className="" fontSize="large" /> */}
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}

                                            </div>
                                            <div className="add_img" onClick={() => handleOpenEditDialog(item)} >
                                                <div className="add_img_btn">
                                                    +
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
            {isOpenGallary &&
                <GalaryImages
                    onClose={() => setIsOpenGallary(false)}
                    initIndex={index}
                    data={gallerySelect}
                />
            }
            {isOpenEdit && (
                <EditHistory
                    isOpen={isOpenEdit}
                    onClose={() => setIsOpenEdit(false)}
                    data={historySelected}
                    reloadData={reloadData}
                />
            )}
        </>
    )
}

export default DetailResult;