import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActions from "../../../../core/app.store";
import ShowNotification from "../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../utils/configuration";
import * as viVN from "../../../../language/vi-VN.json";
import * as helper from "../../helper/helper";
import * as rsAction from "../../../../redux/store/remote-sensing/remote-sensing.store";
//--- Mui
import { DropzoneArea } from 'material-ui-dropzone'
import {
    DialogActions,
    Button,
    TextField,
    DialogContent,
    DialogTitle,
    Dialog,
    Typography,
    IconButton,
    Tooltip,
    makeStyles,
    Checkbox,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import DateRangeIcon from "@material-ui/icons/DateRange";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SuggestionAddress from "../../components/suggestion-address/suggestion-address.view";
import FormAddAddress from "../../components/form/form-add-address.view";
import * as config from "../../../../utils/configuration";
import FileManagement from "../../../../components/file_management/file_management";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import dateformat from "dateformat";
import { getUserInfo } from "../../../../utils/configuration";
import { formatAddress } from "../../helper/helper";

const handleSetFiles = (data) => {
    let listImg = []
    data.galaryImage && data.galaryImage.length > 0 && data.galaryImage.map((item, index) => {
        listImg.push({
            fileId: item,
            filePreview: data.galaryImageFile[index],
            fileName: `anhbiendong-${item}`,
        })
    })
    return listImg;
}

function EditHistory(props) {
    const { isOpen, onClose, data, showLoading, pendingId, reloadData } = props;
    const user = getUserInfo();

    const { register, handleSubmit, setError, errors, clearErrors, setValue } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });
    const [isHot, setIsHot] = useState(true);
    const [openAddAddress, setOpenAddAddress] = useState(false);
    const [addressSelected, setAddressSelected] = useState({
        id: data.addressId, name: formatAddress(data),
        value: data.addressId, label: formatAddress(data)
    });
    const [userHandleModel, setUserHandleModel] = useState([]);
    const [userHandleSelected, setUserHandleSelected] = useState();
    const [avatar, setAvatar] = useState([]);
    const [gallery, setGallery] = useState([{ fileName: data.title, filePreview: data.filePath }]);
    const [remoteSensingHistory, setRemoteSensingHistory] = useState([helper.rsHistoryItem]);
    const [files, setFiles] = useState(handleSetFiles(data));
    const [filesTemp, setFilesTemp] = useState([]);
    const [isShow, setShow] = useState(false);
    const [publishDate, setPublishDate] = useState(data.eventDate);

    useEffect(() => {
        // GetLookupUserhandle();
        // handleSetValue();
        console.log('user', user)
    }, []);

    useEffect(() => { console.log('data', data) }, [data])

    const handleSetValue = () => {
        setValue('statusId', data.reflectionStatusId)
        clearErrors("statusId");
        setValue('userHandleId', data.userHandlerId)
        clearErrors("userHandleId");
    }

    const GetLookupUserhandle = () => {
        showLoading(true);
        rsAction.GetLookupUser().then(res => {
            if (res.content && res.content.length > 0) {
                setUserHandleModel(res.content)
                setUserHandleSelected(res.content.find(item => item.id == data.userHandlerId))
                showLoading(false);
            }
        }).catch(err => {
            err &&
                err.errorType &&
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            showLoading(false);
        })
    }

    const handleChangeAddress = (data) => {
        setAddressSelected(data);
    }

    const onSubmit = formdata => {
        console.log('data', { ...formdata})
        let formData = new FormData();
        formData.append('Id', data.id);
        formData.append('ReflectTheSceneId', data.reflectTheSceneId);
        formData.append('Content', data.content);
        formData.append('UserHandlerId', data.userHandlerId ?? user?.userId);
        formData.append('EventDate', dateformat(data.eventDate, "mm-dd-yyyy"));
        // if (avatar.length > 0) formData.append('image', avatar[0]);
        if (gallery.length > 0) {
            gallery.map(item => {
                formData.append('galaryImages', item);
            })
        }
        if (files.length > 0) {
            files.map(item => {
                formData.append('GalaryImage', item.fileId)
            })
        }
        showLoading(true)
        rsAction.UpdateHistory(formData).then(res => {
            res && ShowNotification("Cập nhật thành công", NotificationMessageType.Success);
            onClose();
            showLoading(false);
            reloadData && reloadData();
        }).catch(err => {
            showLoading(false);
            ShowNotification("Xảy ra lỗi vui lòng liên hệ admin", NotificationMessageType.Error);
        });
    }

    const onOpenSelectFile = () => {
        setShow(true);
        setFilesTemp(files);
    };

    const onCloseSelectFile = () => {
        setShow(false);
        setFiles(filesTemp);
    };

    const onSaveSelectFile = () => {
        setShow(false);
        setGallery([]);
    };

    const handleChangeHistory = (indexSelect, data, type) => {
        console.log('data', data, type)
        let curr = remoteSensingHistory;
        curr[indexSelect][type] = data;
        setRemoteSensingHistory(curr);
        console.log('curr', curr)
    }

    const handleChangeAvatar = (files) => {
        setGallery(files);
    }

    return (
        <>
            <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth="md" className="form_rs">
                <DialogTitle disableTypography className="border-bottom">
                    <Typography variant="h6">Cập nhật biến động</Typography>
                    <IconButton
                        aria-label="close"
                        // className={classes.closeButton}
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                {data && (
                    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                        <DialogContent className="pt-4 pb-2">

                            {/* --- content --- */}
                            {/* <div className="form-group">
                                <label className="text-dark fw-6">Nội dung<span className="required"></span></label>
                                <textarea
                                    name="content"
                                    rows="5"
                                    defaultValue={data.content}
                                    ref={register({ required: true, maxLength: 500 })}
                                    maxLength="500"
                                    className={
                                        "form-control" +
                                        (errors.content && errors.content.type === "required"
                                            ? " is-invalid"
                                            : "")
                                    }
                                ></textarea>
                                {errors.content && errors.content.type === "required" && (
                                    <span className="error">Trường này là bắt buộc</span>
                                )}
                                {errors.content && errors.content.type === "maxLength" && (
                                    <span className="error">Trường này không quá 500 ký tự</span>
                                )}

                            </div> */}

                            {/* --- status addAddress --- */}
                            {/* <div className="form-group">
                                <div className="row">
                                    {userHandleSelected && userHandleModel.length > 0 && (
                                        <div className="col-6">
                                            <label className="text-dark fw-6">
                                                Người xử lý<span className="required"></span>
                                            </label>

                                            <Autocomplete
                                                options={userHandleModel}
                                                getOptionLabel={(option) =>
                                                    typeof option === "string" ? option : option.userName
                                                }
                                                value={userHandleSelected}
                                                onChange={(event, newValue) => {
                                                    setUserHandleSelected(newValue);
                                                    setValue("userHandleId", newValue.id);
                                                    console.log('newValue', newValue)
                                                }}
                                                disableClearable={true}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        name="userHandleId"
                                                        // {...register('userHandleId', { required: true })}
                                                        error={
                                                            errors.userHandleId &&
                                                            errors.userHandleId.type === "required"
                                                        }
                                                        variant="outlined"
                                                        size="small"
                                                    />
                                                )}
                                            />
                                            {errors.userHandleId &&
                                                errors.userHandleId.type === "required" && (
                                                    <span className="error">Trường này là bắt buộc</span>
                                                )}
                                        </div>
                                    )}
                                    {publishDate && (
                                        <div className="col-md-6">
                                            <label className="text-dark">
                                                Ngày biến động<span className="required"></span>
                                            </label>
                                            <div className="table_header_filter_commune">
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        variant="outlined"
                                                        id="publishDate"
                                                        name="publishDate"
                                                        onChange={(date) => date && setPublishDate(date)}
                                                        value={publishDate}
                                                        format="dd/MM/yyyy"
                                                        fullWidth
                                                        showTodayButton={true}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                variant="standard"
                                                                fullWidth
                                                                margin="normal"
                                                                required
                                                                InputProps={{
                                                                    endAdornment: <DateRangeIcon />,
                                                                }}
                                                            />
                                                        )}
                                                        error={
                                                            errors.publishDate &&
                                                            errors.publishDate.type === "required"
                                                        }
                                                    />
                                                </MuiPickersUtilsProvider>
                                                {errors.publishDate &&
                                                    errors.publishDate.type === "required" && (
                                                        <span className="error">
                                                            Trường này là bắt buộc
                                                        </span>
                                                    )}
                                            </div>

                                        </div>
                                    )}
                                </div>
                            </div> */}
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-12">
                                        <label className="text-dark fw-6">
                                            Ảnh biến động
                                        </label>
                                        <div className="row">
                                            <div className="col-12">
                                                <div>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={onOpenSelectFile}
                                                    >
                                                        Chọn ảnh từ hệ thống
                                                    </Button>
                                                    <TextField
                                                        // inputRef={register({ required: true })}
                                                        type="hidden"
                                                        name="image"
                                                        value={
                                                            (files && files.length > 0 && files[0].fileName) || ""
                                                        }
                                                    />
                                                    {/* {errors.image && errors.image.type === "required" && (
                                                    <p className="error">Trường này là bắt buộc</p>
                                                )} */}
                                                </div>
                                                <div className="gallery_preview">
                                                    {!isShow &&
                                                        files &&
                                                        files.length > 0 &&
                                                        files.map((item) => (
                                                            <div key={item.fileName} style={{ width: "150px" }}>
                                                                <img
                                                                    src={config.APIUrlDefault + item.filePreview}
                                                                    alt={item.fileName}
                                                                    className="img-fluid mt-2 mb-2"

                                                                />
                                                            </div>
                                                        ))}
                                                </div>

                                            </div>
                                            <div className="col-12">
                                                <DropzoneArea
                                                    filesLimit={99}
                                                    acceptedFiles={['image/*']}
                                                    maxFileSize={500000000}
                                                    onChange={(files) => handleChangeAvatar(files)}
                                                    showFileNames={true}
                                                    dropzoneText="Tải ảnh lên"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <DialogActions className="border-top">
                                <Button
                                    type="submit"
                                    onClick={onClose}
                                    variant="contained"
                                    startIcon={<CloseIcon />}
                                >
                                    Hủy
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    startIcon={<SaveIcon />}
                                >
                                    Lưu
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </form>
                )}
                {isShow && (
                    <Dialog
                        onClose={onCloseSelectFile}
                        open={isShow}
                        fullWidth={true}
                        maxWidth="md"
                        className="dialog-preview-form"
                    >
                        <DialogTitle disableTypography>
                            <Typography variant="h6">Quản lý file</Typography>
                            <IconButton
                                aria-label="close"
                                // className={classes.closeButton}
                                onClick={onCloseSelectFile}
                            >
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent dividers>
                            <FileManagement
                                multiple
                                files={files}
                                setFiles={setFiles}
                                acceptedFiles={["jpeg", "png", "jpg", "gif"]}
                            />
                        </DialogContent>

                        <DialogActions>
                            <Button
                                type="button"
                                onClick={onCloseSelectFile}
                                variant="contained"
                                startIcon={<CloseIcon />}
                            >
                                Hủy
                            </Button>
                            {files && files.length > 0 && (
                                <Button
                                    type="button"
                                    color="primary"
                                    variant="contained"
                                    startIcon={<SaveIcon />}
                                    onClick={onSaveSelectFile}
                                >
                                    Lưu
                                </Button>
                            )}
                        </DialogActions>
                    </Dialog>
                )}

                {openAddAddress && (
                    <FormAddAddress
                        isOpen={openAddAddress}
                        onClose={() => setOpenAddAddress(false)}
                        setAddressSelected={setAddressSelected}
                    />
                )}
            </Dialog>
        </>
    )
}

const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(EditHistory);