import React, { useState, useEffect } from "react";
import ShowNotification from "../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../utils/configuration";
import AsyncSelect from 'react-select/async';
import * as addressAction from "../../../../redux/store/address-management/address.store";
import { convertAddress } from "../../helper/helper";

var query = null;

function SuggestionAddress(props) {
    const { handleChoose, addressSelected } = props;
    useEffect(()=>{console.log('address', addressSelected)},[])
    const loadOptions = (inputValue, callback) => {
        if (query) {
            clearTimeout(query);
        }

        if (inputValue.length > 2) {
            query = setTimeout(() => {
                if (inputValue) {
                    const getListAddress = async () => {
                        let results = [];
                        const res = await addressAction.AutoSuggestionAddress(inputValue);
                        if (res.content.items && res.content.items.length > 0) {
                            results = res.content.items.map(item => {
                                return {
                                    ...item,
                                    value: item.id,
                                    label: item.name,
                                };
                            })
                        }
                        if (results.length == 0) {
                            ShowNotification(
                                'Vui lòng nhập địa chỉ khác, hoặc thêm địa chỉ mới',
                                NotificationMessageType.Warning,
                                'center'
                            );
                        }
                        callback(results);
                    };
                    getListAddress();
                }
            }, 800);
        } else {
            callback([]);
        }
    };

    return (
        <div>
            <AsyncSelect
                className='search_input_select'
                placeholder='Nhập địa chỉ để tìm kiếm'
                isLoading={false}
                loadOptions={loadOptions}
                onChange={data => {
                    handleChoose(data);
                }}
            
                value={addressSelected}
                components={{ DropdownIndicator: null, LoadingIndicator: null, ClearIndicator: null }}
                // styles={customStyles}
                noOptionsMessage={() => 'Nhập địa chỉ để tìm kiếm'}
            />
        </div>
    )
}

export default SuggestionAddress;