import React from "react";
import { listLayerKind } from "./constant";
export default class EditLayerSettingView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_check: this.props.data.is_check,
      layerName: this.props.data.name,
      maxZoom: this.props.data.max_zoom,
      minZoom: this.props.data.min_zoom,
      z_index: this.props.data.z_index,
      layerKindId: this.props.data.layer_kind_id,
    };
  }

componentDidMount() {
  console.log('fdgfhjdfhgdfg', this.props.data.layer_kind_id, this.props.data.name)
}

  componentWillUnmount() {
    this.props.setData({
      ...this.props.data,
      is_check: this.state.is_check,
      name: this.state.layerName,
      max_zoom: this.state.maxZoom,
      min_zoom: this.state.minZoom,
      z_index: this.state.z_index,
      layer_kind_id: this.state.layerKindId,
    });
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <label for="layerNameInput">Tên layer</label>
              <input
                type="text"
                className="form-control"
                value={this.state.layerName}
                onChange={(event) => {
                  this.setState({ layerName: event.target.value });
                }}
                id="layerNameInput"
                placeholder="Nhập tên layer"
              />
            </div>

          </div>
          <div className="col-6">
            <div className="form-group">
              <label for="layerNameInput">Loại layer</label>
              <select
                class="form-select"
                onChange={(event) => this.setState({ layerKindId: event.target.value })}
                aria-label="Default select example"
                placeholder='Chọn loại layer'
              >
                {listLayerKind.map(item => (
                  <option value={item.value} selected={parseInt(item.value) === this.state.layerKindId}>{item.name}</option>
                ))}
              </select>
            </div>

          </div>
        </div>
        <div className="container-fluid m-0 p-0">
          <div className="row">
            <div className="col-4">
              <div className="form-group">
                <label for="ZindexInput">Z index</label>
                <input
                  type="number"
                  className="form-control"
                  id="ZindexInput"
                  value={this.state.z_index}
                  onChange={(event) => {
                    this.setState({ z_index: event.target.value });
                  }}
                  placeholder="Nhập tên layer"
                />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label for="MinzoomInput">Min zoom</label>
                <input
                  type="number"
                  className="form-control"
                  id="MinzoomInput"
                  value={this.state.minZoom}
                  onChange={(event) => {
                    this.setState({ minZoom: event.target.value });
                  }}
                  placeholder="Nhập tên layer"
                />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label for="MaxzoomInput">Max zoom</label>
                <input
                  type="number"
                  className="form-control"
                  id="MaxzoomInput"
                  value={this.state.maxZoom}
                  onChange={(event) => {
                    this.setState({ maxZoom: event.target.value });
                  }}
                  placeholder="Nhập tên layer"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            checked={this.state.is_check}
            onClick={() => this.setState({ is_check: !this.state.is_check })}
            id="defaultViewCheckbox"
          />
          <label className="form-check-label" for="defaultViewCheckbox">
            Hiển thị mặc định
          </label>
        </div>
      </div>
    );
  }
}
