import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActions from "../../../../../core/app.store";
import ShowNotification from "../../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../../utils/configuration";
import * as viVN from "../../../../../language/vi-VN.json";
import * as helper from "../../../helper/helper";
import * as rsAction from "../../../../../redux/store/remote-sensing/remote-sensing.store";
//--- Mui
import { DropzoneArea } from 'material-ui-dropzone'
import {
    DialogActions,
    Button,
    TextField,
    DialogContent,
    DialogTitle,
    Dialog,
    Typography,
    IconButton,
    Tooltip,
    makeStyles,
    Checkbox,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SuggestionAddress from "../../../components/suggestion-address/suggestion-address.view";
import FormAddAddress from "../../../components/form/form-add-address.view";
import * as config from "../../../../../utils/configuration";
import FileManagement from "../../../../../components/file_management/file_management";
import { getUserInfo } from "../../../../../utils/configuration";

import { formatAddress } from "../../../helper/helper";

const statusModels = [
    { id: 2, name: 'Chưa xử lý' },
    { id: 3, name: 'Đã xử lý' },
]

function EditRemoteSensing(props) {
    const { isOpen, onClose, data, showLoading, pendingId, reloadData } = props;
    const user = getUserInfo();

    const { register, handleSubmit, setError, errors, clearErrors, setValue } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });
    const [isHot, setIsHot] = useState(true);
    const [openAddAddress, setOpenAddAddress] = useState(false);
    const [addressSelected, setAddressSelected] = useState({
        id: data.addressId, name: formatAddress(data),
        value: data.addressId, label: formatAddress(data)
    });
    const [statusModel, setStatusModel] = useState(statusModels);
    const [statusSelected, setStatusSelected] = useState(statusModels.find(item => item.id == data.reflectionStatusId));
    const [userHandleModel, setUserHandleModel] = useState([]);
    const [userHandleSelected, setUserHandleSelected] = useState();
    const [avatar, setAvatar] = useState([]);
    const [gallery, setGallery] = useState([{ fileName: data.title, filePreview: data.filePath }]);
    const [remoteSensingHistory, setRemoteSensingHistory] = useState([helper.rsHistoryItem]);
    const [files, setFiles] = useState(data.filePath ? [{ fileId: data.fileId, fileName: data.title, filePreview: data.filePath }] : []);
    const [filesTemp, setFilesTemp] = useState([]);
    const [isShow, setShow] = useState(false);

    useEffect(() => {
        GetLookupUserhandle();
        handleSetValue();
    }, []);

    const handleSetValue = () => {
        setValue('statusId', data.reflectionStatusId)
        clearErrors("statusId");
        setValue('userHandleId', data.userHandlerId)
        clearErrors("userHandleId");
    }

    // const GetLookupStatus = () => {
    //     showLoading(true);
    //     rsAction.GetLookupStatus().then(res => {
    //         if (res.content && res.content.length > 0) {
    //             setStatusModel(res.content)
    //             showLoading(false);
    //         } else setStatusModel([])
    //     }).catch(err => {
    //         err &&
    //             err.errorType &&
    //             ShowNotification(
    //                 viVN.Errors[err.errorType],
    //                 NotificationMessageType.Error
    //             );
    //         showLoading(false);
    //     })
    // }

    const GetLookupUserhandle = () => {
        showLoading(true);
        rsAction.GetLookupUser().then(res => {
            if (res.content && res.content.length > 0) {
                setUserHandleModel(res.content)
                setUserHandleSelected(res.content.find(item => item.id == data.userHandlerId))
                showLoading(false);
            }
        }).catch(err => {
            err &&
                err.errorType &&
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            showLoading(false);
        })
    }

    const handleChangeAddress = (data) => {
        setAddressSelected(data);
    }
    useEffect(() => {
        console.log('addressSelected', addressSelected)
    }, [addressSelected])

    const onSubmit = formdata => {
        console.log('data', { ...formdata, addressSelected: addressSelected })

        if (addressSelected) {
            let formData = new FormData();
            formData.append('id', data.id);
            formData.append('Title', formdata.title);
            formData.append('Content', formdata.content);
            formData.append('UserHandlerId', data.userHandlerId ?? user?.userId);
            formData.append('AddressId', addressSelected.id);
            formData.append('ReflectionStatusId', statusSelected.id);
            // if (avatar.length > 0) formData.append('image', avatar[0]);
            if (gallery.length > 0) {
                gallery.map(item => {
                    formData.append('image', item);
                })
            } else if (files.length > 0) {
                formData.append('FileId', files[0].fileId)
            }
            showLoading(true)
            rsAction.UpdateRemoteSensing(formData).then(res => {
                res && ShowNotification("Cập nhật thành công", NotificationMessageType.Success);
                onClose();
                showLoading(false);
                reloadData && reloadData();
            }).catch(err => {
                showLoading(false);
                ShowNotification("Xảy ra lỗi vui lòng liên hệ admin", NotificationMessageType.Error);
            });
        } else {
            ShowNotification("Vui lòng chọn địa chỉ hoặc thêm mới", NotificationMessageType.Error, 'center');
        }

    }

    const onOpenSelectFile = () => {
        setShow(true);
        setFilesTemp(files);
    };

    const onCloseSelectFile = () => {
        setShow(false);
        setFiles(filesTemp);
    };

    const onSaveSelectFile = () => {
        setShow(false);
        setGallery([]);
    };

    const handleChangeHistory = (indexSelect, data, type) => {
        console.log('data', data, type)
        let curr = remoteSensingHistory;
        curr[indexSelect][type] = data;
        setRemoteSensingHistory(curr);
        console.log('curr', curr)
    }

    const handleChangeAvatar = (files) => {
        setGallery(files);
        if (files && files.length > 0) setFiles([]);
    }

    useEffect(() => { console.log('remoteSensingHistory', remoteSensingHistory) }, [remoteSensingHistory])
    return (
        <>
            <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth="md" className="form_rs">
                <DialogTitle disableTypography className="border-bottom">
                    <Typography variant="h6">Cập nhật biến động</Typography>
                    <IconButton
                        aria-label="close"
                        // className={classes.closeButton}
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                {data && (
                    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                        <DialogContent className="pt-4 pb-2">

                            {/* --- title --- */}
                            <div className="form-group">
                                <label className="text-dark fw-6">
                                    Tiêu đề<span className="required"></span>
                                </label>
                                <TextField
                                    name="title"
                                    error={
                                        errors.title &&
                                        (errors.title.type === "required" ||
                                            errors.title.type === "maxLength")
                                    }
                                    fullWidth
                                    type="text"
                                    className="form-control"
                                    defaultValue={data.title}
                                    //   inputProps={{ maxLength: 200 }}
                                    inputRef={register({ required: true, maxLength: 150 })}
                                    inputProps={{ maxLength: 150 }}
                                    onChange={(e) =>
                                        setValue("title", e.target.value)
                                    }
                                />
                                {errors.title && errors.title.type === "required" && (
                                    <span className="error">Trường này là bắt buộc</span>
                                )}
                                {errors.title && errors.title.type === "maxLength" && (
                                    <span className="error">Trường này không quá 150 ký tự</span>
                                )}
                            </div>

                            {/* --- description --- */}
                            <div className="form-group">
                                <label className="text-dark fw-6">Nội dung<span className="required"></span></label>
                                <textarea
                                    name="content"
                                    rows="5"
                                    defaultValue={data.content}
                                    ref={register({ required: true, maxLength: 500 })}
                                    maxLength="500"
                                    className={
                                        "form-control" +
                                        (errors.content && errors.content.type === "required"
                                            ? " is-invalid"
                                            : "")
                                    }
                                ></textarea>
                                {errors.content && errors.content.type === "required" && (
                                    <span className="error">Trường này là bắt buộc</span>
                                )}
                                {errors.content && errors.content.type === "maxLength" && (
                                    <span className="error">Trường này không quá 500 ký tự</span>
                                )}

                            </div>

                            {/* --- status addAddress --- */}
                            <div className="form-group">
                                <div className="row">
                                    {userHandleModel && userHandleSelected && userHandleModel.length > 0 && (
                                        <div className="col-6">
                                            <label className="text-dark fw-6">
                                                Người xử lý<span className="required"></span>
                                            </label>

                                            <Autocomplete
                                                options={userHandleModel}
                                                getOptionLabel={(option) =>
                                                    typeof option === "string" ? option : option.userName
                                                }
                                                value={userHandleSelected}
                                                onChange={(event, newValue) => {
                                                    setUserHandleSelected(newValue);
                                                    setValue("userHandleId", newValue.id);
                                                    console.log('newValue', newValue)
                                                }}
                                                disableClearable={true}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        name="userHandleId"
                                                        // {...register('userHandleId', { required: true })}
                                                        error={
                                                            errors.userHandleId &&
                                                            errors.userHandleId.type === "required"
                                                        }
                                                        variant="outlined"
                                                        size="small"
                                                    />
                                                )}
                                            />
                                            {errors.userHandleId &&
                                                errors.userHandleId.type === "required" && (
                                                    <span className="error">Trường này là bắt buộc</span>
                                                )}
                                        </div>
                                    )}

                                    {statusModel && statusSelected && statusModel.length > 0 && (
                                        <div className="col-6">
                                            <label className="text-dark fw-6">
                                                Trạng thái<span className="required"></span>
                                            </label>

                                            <Autocomplete
                                                options={statusModel}
                                                getOptionLabel={(option) =>
                                                    typeof option === "string" ? option : option.name
                                                }
                                                value={statusSelected}
                                                onChange={(event, newValue) => {
                                                    setStatusSelected(newValue);
                                                    setValue("statusId", newValue.id);
                                                    console.log('newValue', newValue)
                                                }}
                                                disableClearable={true}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        name="statusId"
                                                        // {...register('statusId', { required: true })}
                                                        error={
                                                            errors.statusId &&
                                                            errors.statusId.type === "required"
                                                        }
                                                        variant="outlined"
                                                        size="small"
                                                    />
                                                )}
                                            />
                                            {errors.statusId &&
                                                errors.statusId.type === "required" && (
                                                    <span className="error">Trường này là bắt buộc</span>
                                                )}
                                        </div>
                                    )}


                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    {addressSelected && (
                                        <div className="col-12">
                                            <label className="text-dark fw-6">
                                                Địa chỉ<span className="required"></span>
                                            </label>
                                            <div className="row align-items-center">
                                                <div className="col-12 col-md-9 col-lg-9">
                                                    <SuggestionAddress
                                                        handleChoose={handleChangeAddress}
                                                        addressSelected={addressSelected}
                                                        setAddressSelected={setAddressSelected}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-3 col-lg-3">
                                                    <button className="btn_rs" onClick={() => setOpenAddAddress(true)}>+ Thêm địa chỉ</button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-12">
                                        <label className="text-dark fw-6">
                                            Ảnh avatar
                                        </label>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={onOpenSelectFile}
                                                    >
                                                        Chọn ảnh từ hệ thống
                                                    </Button>
                                                    <TextField
                                                        // inputRef={register({ required: true })}
                                                        type="hidden"
                                                        name="image"
                                                        value={
                                                            (files && files.length > 0 && files[0].fileName) || ""
                                                        }
                                                    />
                                                    {/* {errors.image && errors.image.type === "required" && (
                                                    <p className="error">Trường này là bắt buộc</p>
                                                )} */}
                                                </div>
                                                {!isShow &&
                                                    files &&
                                                    files.length > 0 &&
                                                    files.map((item) => (
                                                        <div key={item.fileName} style={{ width: "150px" }}>
                                                            <img
                                                                src={config.APIUrlDefault + item.filePreview}
                                                                alt={item.fileName}
                                                                className="img-fluid mt-2"
                                                                style={{
                                                                    width: "auto",
                                                                    height: "auto",
                                                                    maxWidth: "100%",
                                                                    maxHeight: "100%",
                                                                }}
                                                            />
                                                        </div>
                                                    ))}
                                            </div>
                                            <div className="col-md-6">
                                                <DropzoneArea
                                                    filesLimit={1}
                                                    acceptedFiles={['image/*']}
                                                    maxFileSize={500000000}
                                                    onChange={(files) => handleChangeAvatar(files)}
                                                    showFileNames={true}
                                                    dropzoneText="Tải ảnh lên"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <DialogActions className="border-top">
                                <Button
                                    type="submit"
                                    onClick={onClose}
                                    variant="contained"
                                    startIcon={<CloseIcon />}
                                >
                                    Hủy
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    startIcon={<SaveIcon />}
                                >
                                    Lưu
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </form>
                )}
                {isShow && (
                    <Dialog
                        onClose={onCloseSelectFile}
                        open={isShow}
                        fullWidth={true}
                        maxWidth="md"
                        className="dialog-preview-form"
                    >
                        <DialogTitle disableTypography>
                            <Typography variant="h6">Quản lý file</Typography>
                            <IconButton
                                aria-label="close"
                                // className={classes.closeButton}
                                onClick={onCloseSelectFile}
                            >
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent dividers>
                            <FileManagement
                                files={files}
                                setFiles={setFiles}
                                acceptedFiles={["jpeg", "png", "jpg", "gif"]}
                            />
                        </DialogContent>

                        <DialogActions>
                            <Button
                                type="button"
                                onClick={onCloseSelectFile}
                                variant="contained"
                                startIcon={<CloseIcon />}
                            >
                                Hủy
                            </Button>
                            {files && files.length > 0 && (
                                <Button
                                    type="button"
                                    color="primary"
                                    variant="contained"
                                    startIcon={<SaveIcon />}
                                    onClick={onSaveSelectFile}
                                >
                                    Lưu
                                </Button>
                            )}
                        </DialogActions>
                    </Dialog>
                )}

                {openAddAddress && (
                    <FormAddAddress
                        isOpen={openAddAddress}
                        onClose={() => setOpenAddAddress(false)}
                        setAddressSelected={setAddressSelected}
                    />
                )}
            </Dialog>
        </>
    )
}

const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(EditRemoteSensing);