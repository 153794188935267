import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";
import * as config from "../../../common/config"

const service = new Service();

export const GetRouteMap4d = (data) => {
    const params = new URLSearchParams();
    params.append("key", "ac543d442b430dcfbe8ed6d89fe5e0d8");
    params.append("mode", "car");
    data.origin && params.append("origin", data.origin);
    data.destination && params.append("destination", data.destination);
    
    return service
    .get("https://api.map4d.vn/sdk/route", params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
  }
  
