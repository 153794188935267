import React, { useState, useRef } from "react";
import * as apiConfig from "../../../../api/api-config";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import closeIcon from "./../../../../assets/icon/close-menu-white.png";

import "./gallery.scss";

function GalaryImages(props) {
    const { isOpen, onClose, data, initIndex } = props;
    const [currentIndex, setCurrentIndex] = useState(initIndex ? initIndex : 1)
    const mainSliderRef = useRef(null);
    const navSliderRef = useRef(null);

    const mainSliderSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: initIndex,
        beforeChange: (current, next) => {
            navSliderRef.current.slickGoTo(next)
            setCurrentIndex(next)
        },
        asNavFor: navSliderRef.current, // Connect the main slider to the navigation slider
        // nextArrow: <SlickArrowRight />,
        // prevArrow: <SlickArrowLeft />,
    };
    const navSliderSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: data.length < 5 ? data.length : 5,
        slidesToScroll: 1,
        initialSlide: initIndex,
        beforeChange: (current, next) => {
            mainSliderRef.current.slickGoTo(next)
            setCurrentIndex(next)
        },
        asNavFor: mainSliderRef.current, // Connect the navigation slider to the main slider
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                }
            },
        ]
    };

    return (
        <div className="gallery_images">
            <div className="gallery_header">
                <p>Thư viện ảnh</p>
                <img src={closeIcon} onClick={() => onClose()} alt="close" />
            </div>
            <div className="gallery_slider">
                <div className="slider_main">
                    <Slider {...mainSliderSettings} ref={mainSliderRef}>
                        {data.length > 0 && data.map((item, index) => (
                            <div key={index} className="gallery_item">
                                <div className="gallery_item_title">
                                    <p>{item.title}</p>
                                </div>
                                <img className="gallery_img" src={apiConfig.api + (item.path ?? item)} alt={item.title ?? 'image'} />
                            </div>

                        ))}
                    </Slider>
                </div>
                <div className="slider_nav">
                    <Slider {...navSliderSettings} ref={navSliderRef}>
                        {data.length > 0 && data.map((item, index) => (
                            <div key={index} className="gallery_item">
                                <img className="gallery_img_nav" src={apiConfig.api + (item.path ?? item)} alt={item.title ?? 'image'} />
                            </div>
                        ))}
                    </Slider>
                </div>
                <div className='pagination_gallery'>
                    <p>{currentIndex + 1} / {data.length}</p>
                </div>
            </div>
        </div>
    )
}

export default GalaryImages;