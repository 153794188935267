import React, { useEffect, useState } from "react";
//--- Mui
import { IconButton, Tooltip, Snackbar } from "@material-ui/core";
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import EditIcon from '@material-ui/icons/Edit';
import HistoryIcon from "@material-ui/icons/History";
import DirectionsIcon from '@material-ui/icons/Directions';
import CollectionsIcon from '@material-ui/icons/Collections';
import MyLocationIcon from "@material-ui/icons/MyLocation";
import { formatAddress } from "../../helper/helper";
import { functionShowOverlayFromSidebar } from "../map/controller";
import dateFormat from "dateformat";
import apiConfig from "../../../../api/api-config";
import { formatCoordinate } from "../../helper/helper";
import history from "../../../../common/history";
import { useLocation } from "react-router-dom";

function ListRemoteSensing(props) {
    const { getRoute, route, currDestination } = props;
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const { data, overlayRef, mapRef } = props;
    const [isOpenSnackbar, setIsOpenSnackbar] = useState(false);
    const [coordSelect, setCoordSelect] = useState(
        `${queryParams.get('latitude')},${queryParams.get('longitude')}`
    )

    const handleClickViewMap = (lng, lat) => {
        if (`${lat},${lng}` == coordSelect) {
            queryParams.delete('latitude');
            queryParams.delete('longitude');
            setCoordSelect('')
            history.push({
                search: queryParams.toString(),
            })
        } else {
            functionShowOverlayFromSidebar(mapRef.current, overlayRef.current, { coordinate: [lng, lat] })
            setCoordSelect(`${lat},${lng}`)
            queryParams.set('latitude', lat);
            queryParams.set('longitude', lng);
            history.push({
                search: queryParams.toString(),
            })
            navigator.clipboard.writeText(`${lat},${lng}`);
            setIsOpenSnackbar(true)
        }

    }

    return (
        <div className="list_rs_container">
            {data && data.map((item, index) => {
                let imgSrc = null;
                let status = null;
                if (item.reflectionStatusId == 2) {
                    imgSrc = require("../img/red-dot.png");
                    status = "Đang xử lý";
                } else {
                    imgSrc = require("../img/green-dot.png");
                    status = "Đã xử lý";
                }
                const isActive = item.latitude == queryParams.get('latitude')

                return (
                    <div key={item.id}>
                        <div className="rs_item">
                            <div className="rs_item_">
                                <div className="dot_status">
                                    <img src={imgSrc} alt="status" title={status} />
                                </div>
                                <div className="rs_address">
                                    <div className="title">{item?.title}</div>
                                    <div className="address">{formatAddress(item)}</div>
                                    <div className="coord">{formatCoordinate(item.latitude)}, {formatCoordinate(item.longitude)}</div>
                                </div>
                            </div>
                            <div className="rs_item_controll">
                                <Tooltip title="Xem trên bản đồ" onClick={() => handleClickViewMap(item.longitude, item.latitude)}>
                                    <MyLocationIcon fontSize="medium" className={isActive ? 'active' : ''} />
                                </Tooltip>
                                <Tooltip
                                    title={`${item.latitude},${item.longitude}` == currDestination ? route?.distance.text : 'chỉ đường'}
                                    onClick={() => getRoute(`${item.latitude},${item.longitude}`)}
                                >
                                    <DirectionsIcon
                                        fontSize="medium"
                                        className={`${item.latitude},${item.longitude}` == currDestination ? 'active' : ''}
                                    />
                                </Tooltip>
                                <div
                                    className="button_collapse collapsed"
                                    data-toggle="collapse"
                                    href={`#collapse-${index}`}
                                    role="button"
                                    aria-expanded="false"
                                    aria-controls={`collapse-${index}`}
                                >
                                    <Tooltip title="Xem ảnh biến động" className="arrow_left">
                                        <CollectionsIcon fontSize="medium" />
                                    </Tooltip>
                                    <Tooltip className="arrow_down" title="Thu gọn">
                                        <CollectionsIcon fontSize="medium" style={{color: '#f50057'}}/>
                                    </Tooltip>
                                </div>
                            </div>
                            <Snackbar
                                className="rs_item_controll_snackbar"
                                message="Tọa độ đã được sao chép"
                                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                                autoHideDuration={2000}
                                onClose={() => setIsOpenSnackbar(false)}
                                open={isOpenSnackbar}
                            />

                        </div>
                        <div className="collapse" id={`collapse-${index}`}>
                            <div className="card card-body">
                                <div className="list_img">
                                    {item?.reflectTheSceneHistoryViewModel &&
                                        item.reflectTheSceneHistoryViewModel.map((historyItem, historyIndex) => (
                                            <div key={`img-${historyIndex}`} className="list_img_item">
                                                <div className="date">
                                                    Ngày tạo: {dateFormat(historyItem.eventDate, "dd/mm/yyyy")}
                                                </div>
                                                <div className="list">
                                                    {historyItem.galaryImageFile.map((img, imgIndex) => (
                                                        <div key={imgIndex} className="img">
                                                            <img src={apiConfig.api + img} alt="" />
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default ListRemoteSensing;