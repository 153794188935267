import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";
import * as config from "../../../common/config"

const service = new Service();

export const GetListUnit = (pageIndex = 1, pageSize = config.Configs.DefaultPageSize, sortExpression = "", name = "") => {
    const params = new URLSearchParams();
    params.append("isDelete", false);
    params.append("pageIndex", pageIndex);
    params.append("pageSize", pageSize);
    sortExpression && params.append("Sorting", sortExpression);
    name && params.append("keyword", name.trim());
    return service.get(ApiUrl.GetListUnit, params).then((res) => { return res }).catch(err => { throw err });
}
export const GetDetailUnit = (id) => {
    return service.get(ApiUrl.GetDetailUnit(id)).then(res => { return res }).catch(err => { throw err });
}
export const GetLookupUnit = () => {
    return service.get(ApiUrl.GetLookupUnit).then(res => { return res }).catch(err => { throw err });
}

export const CreateUnit = (body) => {
    return service.post(ApiUrl.CreateUnit, body).then(res => { return res }).catch(err => { throw err });
}

export const UpdateUnit = (body) => {
    return service.post(ApiUrl.UpdateUnit, body).then(res => { return res }).catch(err => { throw err });
}

export const DeleteUnit = (id) => {
    return service.postParams(ApiUrl.DeleteUnit(id)).then(res => { return res }).catch(err => { throw err });
}