import React, { useState, useEffect, useRef } from "react";
import SidebarRemoteSensing from "./sidebar/sidebar.view";
import OpenLayerView from "./map/open-layer.view";
import { dataFeaturesFake } from "../helper/helper";
import * as rsAction from "../../../redux/store/remote-sensing/remote-sensing.store";
import * as appActions from "../../../core/app.store";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useLocation } from 'react-router-dom';
import { functionShowOverlayFromSidebar } from "./map/controller";
import { GetRouteMap4d } from "../../../redux/store/map4d/map4d.store";
import ShowNotification from "../../../components/react-notifications/react-notifications";

import "./map-remote-sensing.scss"

function MapRemoteSensing(props) {
    const { showLoading } = props;
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const mapRef = useRef(null);
    const overlayRef = useRef(null);

    const [showSidebar, setShowSidebar] = useState(true);
    const [data, setData] = useState([]);
    const [dataSelected, setDataSelected] = useState();
    const [pagination, setPagination] = useState({});
    const [pageSize, setPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(1);
    const [coord, setCoord] = useState(queryParams.get('longitude'));
    const [route, setRoute] = useState();
    const [currDestination, setCurrDestination] = useState();

    useEffect(() => {
        if (coord == queryParams.get('longitude')) {
            let params = {
                pageSize: queryParams.get('pageSize') || 10,
                pageIndex: queryParams.get('pageIndex') || 1,
                communeId: queryParams.get('communeId') || null,
                streetName: queryParams.get('streetName') || null,
                statusId: queryParams.get("statusId") || null,
            }
            GetListFeature(params);
        } else setCoord(queryParams.get('longitude'))
        if (!queryParams.get('id')) setDataSelected(null)
    }, [location.search])

    useEffect(() => {
        if (queryParams.get('id')) {
            GetDetail(queryParams.get('id'))
        }
    }, [])

    const GetDetail = (id) => {
        showLoading(true);
        rsAction.GetDetailReflectTheScene(id).then(res => {
            if (res && res.content) {
                setDataSelected(res.content)
                showLoading(false)
            }
        }).catch(err => showLoading(false))
    }

    const getRoute = (destination) => {
        if (destination == currDestination) {
            setRoute(null);
            setCurrDestination(null);
        } else {
            setCurrDestination(destination);
            navigator.geolocation.getCurrentPosition((position) => {
                if (position && position.coords) {
                    const origin = `${position.coords.latitude},${position.coords.longitude}`
                    GetRouteMap4d({ origin: origin, destination: destination }).then(res => {
                        // GetRouteMap4d({ origin: '13.973447,108.011024', destination: destination }).then(res => {
                        if (res && res.code == 'ok') {
                            setRoute(res.result.routes[0])
                            console.log('ok')
                        }
                    }).catch(err => {
                        ShowNotification(
                            "Xảy ra lỗi, vui lòng liên hệ với Admin!",
                            'warning',
                        );
                    })
                } else {
                    ShowNotification(
                        "Bật định vị để xác định vị trí của bạn!",
                        'warning',
                        "center"
                    );
                }
            });
        }
    }

    const refreshData = () => {
        let params = {
            pageSize: queryParams.get('pageSize') || 10,
            pageIndex: queryParams.get('pageIndex') || 1,
            communeId: queryParams.get('communeId') || null,
            streetName: queryParams.get('streetName') || null,
        }
        GetListFeature(params);
    }

    const GetListFeature = (params) => {
        showLoading(true);
        rsAction.GetListAll(params).then(res => {
            if (res && res.content) {
                const { items, pageCount, pageIndex, pageSize, totalItemCount } = res.content;
                setData(items);
                setPagination({ ...pagination, pageCount, pageIndex, pageSize, totalItemCount });
            }
            showLoading(false);
        }).catch(err => showLoading(false))
    }
    return (
        <div className="map_rs_container">
            <OpenLayerView
                route={route}
                showSidebar={showSidebar}
                setShowSidebar={setShowSidebar}
                dataFeatures={data}
                featureSelected={dataSelected}
                mapRef={mapRef}
                overlayRef={overlayRef}
                refreshData={refreshData}
            />
            <SidebarRemoteSensing
                getRoute={getRoute}
                route={route}
                currDestination={currDestination}
                pagination={pagination}
                show={showSidebar}
                setShow={setShowSidebar}
                data={data}
                mapRef={mapRef}
                overlayRef={overlayRef}
            />
        </div>
    )
}

const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(MapRemoteSensing);