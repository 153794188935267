import React from 'react';
//---img
import planningIcon from "../../assets/icon/planning-info.png";
import adminstratorIcon from "../../assets/icon/adminstrator.png";
import remoteIcon from "../../assets/icon/remote-sensing.png";
import { UrlCollection } from '../../common/url-collection';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBars,
    faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
    DomainAdminSide,
    TokenKey,
    getUserInfo,
    removeCookies,
    APIUrlDefault,
    setCookies,
} from "../../utils/configuration";
import "./home.scss";
export default function HomePage() {
    const user = getUserInfo();

    const onLogout = () => {
        // removeCookies('DeviceId')
        removeCookies(TokenKey.token);
        removeCookies(TokenKey.returnUrl);
        window.location.replace(DomainAdminSide);
    }

    const listEntry = [
        {
            name: 'Thông tin quy hoạch',
            img: planningIcon,
            linkUrl: UrlCollection.PlanningAnnouncementProcess
        },
        {
            name: 'Biến động xây dựng',
            img: remoteIcon,
            linkUrl: UrlCollection.MapRemoteSensing
        },
        {
            name: 'Quản trị hệ thống',
            img: adminstratorIcon,
            linkUrl: UrlCollection.EmailTemplate
        },
    ]

    return (
        <div className='container entry_screen'>
            <div className="entry_header row">
                <div className="col-8 row">
                    <div className="col-3">
                        <div className="entry_header_logo">
                            <img src={require("../../assets/images/logo.png")} alt="logo" />
                        </div>
                    </div>
                    <div className="col-9">
                        <div className="entry_header_title">
                            PHẦN MỀM CƠ SỞ DỮ LIỆU QUẢN LÝ QUY HOẠCH, CUNG CẤP THÔNG TIN QUY HOẠCH TRỰC TUYẾN TRÊN NỀN TẢNG HỆ THỐNG CÔNG NGHỆ THÔNG TIN ĐỊA LÝ TOÀN CẦU, ĐIỆN TOÁN ĐÁM MÂY TRÊN ĐỊA BÀN thành phố Pleiku
                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <div className="nav-item dropdown no-arrow">
                        <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            id="userDropdown"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <span className="mr-2 d-none d-lg-inline text-gray-600 small">
                                {user && user.fullName}
                            </span>
                            <img
                                className="img-profile rounded-circle"
                                src={//require("../../assets/images/user-default.png"
                                    user && user.avatar && user.avatar !== "null"
                                        ? APIUrlDefault + user.avatar
                                        : process.env.PUBLIC_URL + "/user-default.png"
                                }
                            />
                        </a>

                        <div
                            className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                            aria-labelledby="userDropdown"
                        >
                            {/* <a className="dropdown-item" href="#">
                <FontAwesomeIcon icon={faUser} className="fa-sm fa-fw mr-2 text-gray-400" />Profile
              </a> */}
                            {/* <div className="dropdown-divider"></div> */}
                            <a className="dropdown-item" href="" onClick={() => onLogout()}>
                                <FontAwesomeIcon
                                    icon={faSignOutAlt}
                                    className="fa-sm fa-fw mr-2 text-gray-400"
                                />
                                Logout
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row entry_box">

                {listEntry.map((item, index) => (
                    <a key={index} href={item.linkUrl} className="entry_item">
                        <img src={item.img} alt={item.name} />
                        <div>
                            {item.name}
                        </div>
                    </a>
                ))}

            </div>
        </div>
    )
}