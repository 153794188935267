const HANDLEOUTSIDEFUNCTION = "OPENLAYER/HANDLEOUTESIDEFUNCTION";
const SAVEOPENLAYERCONTROLLER = 'OPENLAYER/SAVEOPENLAYERCONTROLLER';

const creatActionHandleOutsideFunction = (data) => ({ type: HANDLEOUTSIDEFUNCTION, data: data })
const CreateSaveOpenlayerControllerAction = data => ({ type: SAVEOPENLAYERCONTROLLER, data: data })

export const CreateOpenlayerController = (handleFunction) => {
    return dispatch => {
        dispatch(CreateSaveOpenlayerControllerAction(handleFunction))
    }
}

export const SetHandleOutsideFunction = (data) => {
    return (dispatch) => {
        dispatch(creatActionHandleOutsideFunction(data))
    }
}

const DefaultState = {
    openLayerController: null,
    handleOutSideFunction: () => { },
}

export default function OpenlayerReducer(state = DefaultState, action) {
    switch (action.type) {
        case HANDLEOUTSIDEFUNCTION: return { ...state, handleOutSideFunction: action.data }
        case SAVEOPENLAYERCONTROLLER: return { ...state, openLayerController: action.data }
        default: return state
    }
};