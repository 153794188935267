import React from "react";
import {
    DialogContent,
    DialogTitle,
    Dialog,
} from "@material-ui/core";
import dateformat from "dateformat";

const SimpleDialog = (props) => {
    const { data, isOpen, onClose } = props;

    return (
        <Dialog onClose={onClose} open={isOpen} >
            <DialogTitle>{data.title}</DialogTitle>
            <DialogContent>
                <div>
                    Ngày biến động: {dateformat(data.eventDate, "dd-mm-yyyy")}
                </div>
                <div className="mb-3">
                    Nội dung biến động: {data.content}
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default SimpleDialog;