import React, { useState, useEffect, useRef } from 'react';
import { bindActionCreators } from "redux";
import * as OpenlayerStore from "../../../../redux/store/openlayer/openlayer.store";
import { connect } from "react-redux";

import { Feature, Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
import { DragPan, MouseWheelZoom, defaults } from 'ol/interaction.js';
import { platformModifierKeyOnly } from 'ol/events/condition.js';
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import TileWMS from 'ol/source/TileWMS';
import OSM from "ol/source/OSM";
import Point from "ol/geom/Point";
import { Circle as CircleStyle, Fill, Stroke, Style, Icon } from "ol/style";
import WKT from "ol/format/WKT";
import Polyline from 'ol/format/Polyline';
import Overlay from "ol/Overlay";
import Zoom from 'ol/control/Zoom';
import XYZ from "ol/source/XYZ";
import MousePosition from "ol/control/MousePosition";
import { LineString } from 'ol/geom';
import { createStringXY } from "ol/coordinate";
import { FullScreen, defaults as defaultControls } from 'ol/control.js';
import { useMediaQuery } from '@material-ui/core';
import * as config from "../../../../utils/configuration";
import * as apiConfig from "../../../../api/api-config";
import $ from 'jquery';
import HandleReflectView from "../../components/form/form-handle.view";
import { formatAddress, decodePolyline } from '../../helper/helper';

// import UrlCollection from "../../../../common/url-collection";
import "./open-layer.scss";

import { functionHandleHover, functionShowOverlay, removeHiglightVectorLayer, zoomOut, zoomIn } from "./controller";
import MapControllerView from '../map-controller/map-controller.view';
import * as mapHelper from "../../components/map";
import ShowNotification from '../../../../components/react-notifications/react-notifications';
import { useLocation } from 'react-router-dom';
import history from '../../../../common/history';

export const baseLayer = [
    {
        name: 'Quy hoạch sử dụng đất',
        is_check: false,
        table: "qhc_sdd_tppleiku4326",
        wms: "https://geo.cgis.asia/geoserver/pleiku/wms/qhc_sdd_tppleiku4326",
        z_index: 2,
        style: 'ST_MultiPolygon'
    },
    {
        name: 'Ranh giới',
        is_check: true,
        table: "ranhgioiqh_tppleiku_4326",
        wms: 'https://geo.cgis.asia/geoserver/pleiku/wms/ranhgioiqh_tppleiku_4326',
        z_index: 1,
        style: 'ST_MultiPolygon'
    }
]

function OpenLayerView(props) {
    const { dataFeatures,
        refreshData,
        showSidebar,
        addAddress,
        onClickDefault,
        overlayRef,
        mapRef,
        isAddress,
        isPending,
        pendingData,
        featureSelected,
        route,
    } = props;

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const [map, setMap] = useState(null);
    const [onClick, setOnClick] = useState(onClickDefault);
    const [isOpen, setIsOpen] = useState(false);
    const [pendingId, setPendingId] = useState();

    const handleZoomOut = () => {
        const zoom = map.getView().getZoom();
        map.getView().animate({ zoom: zoom - 1, duration: 1000 });
    };

    const handleZoomIn = () => {
        const zoom = map.getView().getZoom();
        map.getView().animate({ zoom: zoom + 1, duration: 1000 });
    };

    const handleViewFullScreen = () => {
        $('.ol-full-screen-false').click();
    }

    const handleReset = () => {
        removeVectorLayerByClassName('polyline_route');
        removeVectorLayerByClassName('start-feature-vector-layer');
        const zoom = map.getView().getZoom()
        const duration = zoom < 14 ? 2000 : zoom < 17 ? 3000 : 4000
        map.getView().animate({ zoom: 11, duration: duration });
        const buttonClose = document.getElementById("overlay_close");
        if (buttonClose) buttonClose.click();
    }

    const toggleDisplayLayer = (layer, isCheck) => {
        const LayersCurrents = map?.getLayers();
        LayersCurrents.forEach((layerObject) => {
            if (layerObject.getClassName() === layer.table) {
                // console.log('toggleDisplayLayer', layer.table)
                layerObject.setVisible(isCheck)
            }
        });
    }

    const changeBaseMap = (newBasemapUrl) => {
        const LayersCurrents = map?.getLayers();
        if (newBasemapUrl) {
            LayersCurrents.insertAt(
                0,
                new TileLayer({
                    source: new OSM({
                        url: newBasemapUrl,
                        maxZoom: 20,
                    }),
                })
            );
            LayersCurrents.removeAt(1);
        } else {
            LayersCurrents.item(0).setVisible(false);
        }
    };

    const viewCurrentLocaltion = () => {
        navigator.geolocation.getCurrentPosition((position) => {
            if (position && position.coords) {
                if (config.defaultPolygon && mapHelper.isPointInPolygon(
                    position.coords.longitude,
                    position.coords.latitude,
                    config.defaultPolygon
                )) {
                    pinSearchMode([
                        position.coords.longitude,
                        position.coords.latitude,
                    ]);
                } else {
                    ShowNotification(
                        "Vị trí của bạn không nằm trên bản đồ!",
                        'warning',
                        "center"
                    );
                }

            }
        });
        // pinSearchMode([
        //     107.987308, 13.944310
        // ], true);
    };

    const pinSearchMode = (coordinate, animation) => {
        if (animation) {
            map.getView().animate({ center: coordinate, zoom: 14 }, { duration: 500 });
        }
        const LayersCurrents = map.getLayers();
        console.log('LayersCurrents', LayersCurrents)
        const anchorXUnits = "fraction";
        const anchorYUnits = "pixels";
        const PinMarkerLayer = new VectorLayer({
            zIndex: 9999,
            className: "pin-marker-vector-layer",
            source: new VectorSource({
                features: [],
            }),
            style: new Style({
                image: new Icon({
                    anchor: [0.5, 28],
                    anchorXUnits: anchorXUnits,
                    anchorYUnits: anchorYUnits,
                    src: require("../img/location-marker.png")
                }),
            }),
        });
        if (!checkPinMarker()) {
            LayersCurrents.push(PinMarkerLayer);
        }
        LayersCurrents.forEach((layerObject) => {
            if (layerObject.getClassName() === "pin-marker-vector-layer") {
                layerObject.getSource().clear();
                const newMarker = new Feature({
                    type: "icon",
                    geometry: new Point(coordinate),
                });
                layerObject.getSource().addFeature(newMarker);
                return;
            }
        });
    }

    const checkPinMarker = () => {
        let currentLayers = map?.getLayers();
        currentLayers.forEach((LayerObject) => {
            if (LayerObject.getClassName() === "pin-marker-vector-layer") {
                return true;
            }
        });
        return false;
    };

    const handleOnOffViewCoord = (turnOn) => {
        const mapObject = mapRef.current;
        if (turnOn) {
            mapObject.on("click", functionClickViewCoordListener);
        } else {
            mapObject.un("click", functionClickViewCoordListener);
        }
    };

    const functionClickViewCoordListener = (event) => {
        const mapObject = mapRef.current;
        if (map) {
            const clickedCoordinate = mapObject.getEventCoordinate(event);
            if (props.setCoord) {
                props.setCoord(clickedCoordinate);
                pinSearchMode(clickedCoordinate)
            }
            // console.log('Clicked Coordinate:', clickedCoordinate);
        }
    }



    const functionClickShowOverLay = (map, overlay) => {
        map.on("singleclick", function (e) {
            functionShowOverlay(map, overlay, e);
            functionClickShowTooltip(map, map.getEventPixel(e.originalEvent), overlay)
            var hit = this.forEachFeatureAtPixel(e.pixel, function (feature, layer) {
                return true;
            });
            if (hit) {
                this.getTargetElement().style.cursor = 'pointer';
            } else {
                this.getTargetElement().style.cursor = '';
            }
        })
    }

    const functionClickShowTooltip = (map, pixel, overlay, coord) => {
        const pixelSelected = pixel ? pixel : map.getPixelFromCoordinate(coord);
        const overlayContent = document.getElementById("overlay-content1");
        const feature = map?.forEachFeatureAtPixel(pixelSelected, function (
            feature
        ) {
            return feature;
        });
        if (
            feature &&
            feature.getProperties() &&
            feature.getProperties().name
        ) {
            console.log('getCoordinates', feature.getGeometry().getCoordinates())
            console.log('getCoordinatesfdsfsdf', feature.getProperties().data)
            const data = feature.getProperties().data;
            console.log('overlay', overlay)
            overlay.setPosition(feature.getGeometry().getCoordinates())
            overlayContent.style.display = 'block';
            overlayContent.innerHTML = `
                <div class="arrow_"></div>
                <div class="title"> 
                    <p>${data.title}</p>
                </div>
                <div class="content_item">
                    Nội dung - ${data.content}
                </div>
                <div class="content_item">
                    Địa chỉ - ${formatAddress(data)}
                </div>
                <div class="content_item">
                    Trạng thái - ${data.reflectionStatusName}
                </div>
                <div class="content_item">
                    Người xử lý - ${data.userHandlerName ? data.userHandlerName : ''}
            `
            if (data.reflectionStatusId === 2) {
                overlayContent.innerHTML += `
                    <div id="handle-now">
                        Xử lý ngay ->
                    </div></div>
                `
                const clickHandle = document.getElementById("handle-now");
                clickHandle.addEventListener('click', () => {
                    setPendingId(data.id)
                    setIsOpen(!isOpen)
                })
            } else {
                overlayContent.innerHTML += `</div>`
            }
            const buttonClose = document.getElementById("overlay_close");
            buttonClose.addEventListener('click', () => {
                overlayContent.style.display = 'none';
            })
            // const buttonClose = document.getElementById("overlay_close");
            // buttonClose.addEventListener('click', () => {
            //     overlayContent.style.display = 'none';
            //     removeHiglightVectorLayer(map);
            // })
        } else {
            overlayContent.style.display = 'none';
        }
    }

    useEffect(() => {
        if (map && featureSelected) {
            console.log('featureSelected', featureSelected)
            const featureDisplay = [featureSelected];
            const featureIcon = require('../img/blue-marker.png');
            const featureClassName = 'pin-feature-selected-vector-layer';
            displayFeature(featureDisplay, featureIcon, featureClassName, 103);
            map.getView().animate(
                {
                    center: [featureSelected.longitude, featureSelected.latitude],
                    zoom: 17
                },
                { duration: 500 }
            );
            queryParams.set('latitude', featureSelected.latitude);
            queryParams.set('longitude', featureSelected.longitude);
            history.push({
                search: queryParams.toString(),
            })
        } else {
            removeVectorLayerByClassName('pin-feature-selected-vector-layer')
        }
    }, [map, featureSelected])

    useEffect(() => {
        if (map && dataFeatures) {
            console.log(map.getLayers())
            removeVectorLayerByClassName('pin-feature-processing-vector-layer')
            removeVectorLayerByClassName('pin-feature-processed-vector-layer')

            const featureDefaultProcessing = dataFeatures.filter(item => item.reflectionStatusId == 2);
            const processingIcon = require("../img/red-marker.png");
            const processingClassName = 'pin-feature-processing-vector-layer';
            const featureDefaultProcessed = dataFeatures.filter(item => item.reflectionStatusId == 3);
            const processedIcon = require("../img/green-marker.png");
            const processedClassName = 'pin-feature-processed-vector-layer';

            displayFeature(featureDefaultProcessing, processingIcon, processingClassName, 102);
            displayFeature(featureDefaultProcessed, processedIcon, processedClassName);
        }
    }, [map, dataFeatures])

    const displayFeature = (data, icon, className, zIndex) => {
        const defaultListFeature = data;
        const featureDefault = [];
        defaultListFeature &&
            defaultListFeature.length > 0 &&
            defaultListFeature.map((feature) => {
                if (feature.title) {
                    const featureMarker = new Feature({
                        type: 'icon',
                        geometry: new Point([feature.longitude, feature.latitude]),
                        population: 4000,
                        rainfall: 500,
                        data: feature,
                        name: feature.title,
                        // imgUrl: feature.filePath,
                        // id: feature.id
                    });
                    featureDefault.push(featureMarker)
                }
            });
        const iconStyle = new Style({
            image: new Icon({
                src: icon,
                anchorXUnits: 'fraction',
                anchorYUnits: 'pixels',
                anchor: [0.5, 48],
                scale: [0.8, 0.8],
            }),
            cursor: 'pointer'
        });
        const vectorLayer = new VectorLayer({
            source: new VectorSource({
                features: featureDefault,
            }),
            className: className,
            zIndex: zIndex ?? 101,
            style: iconStyle,
        });
        map.addLayer(vectorLayer);
    }

    useEffect(() => {
        if (map && isPending && pendingData) {
            const featureDefault = [];
            const featureMarker = new Feature({
                type: 'icon',
                geometry: new Point([pendingData.longitude, pendingData.latitude]),
                population: 4000,
                rainfall: 500,
                // data: feature,
                // name: feature.title,
                // imgUrl: feature.filePath,
                // id: feature.id
            });
            featureDefault.push(featureMarker);
            const iconStyle = new Style({
                image: new Icon({
                    src: require("../img/red-marker.png"),
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'pixels',
                    anchor: [0.5, 48],
                    scale: [0.8, 0.8],
                }),
                cursor: 'pointer'
            });
            const vectorLayer = new VectorLayer({
                source: new VectorSource({
                    features: featureDefault,
                }),
                className: "pin-feature-vector-layer",
                zIndex: 100,
                style: iconStyle,
            });
            map.addLayer(vectorLayer);
            hightLightVectorLayer(pendingData.geoText)
        }
    }, [map, pendingData])

    const hightLightVectorLayer = (geoText) => {
        if (map) {
            const wktFormat = new WKT();
            const geometry = wktFormat.readGeometry(geoText);
            const vectorSource = new VectorSource({
                features: [new Feature(geometry)],
            });

            const vectorLayer = new VectorLayer({
                source: vectorSource,
                style: new Style({
                    stroke: new Stroke({
                        color: 'rgba(0,230,241,1)',
                        width: 3,
                    }),
                    fill: new Fill({
                        color: 'rgba(223,16, 188,.1)',
                    }),
                }),
            });
            map.addLayer(vectorLayer);
            map.getView().fit(geometry.getExtent(), map.getSize());
        }
    }

    const removeVectorLayerByClassName = (className) => {
        if (map) {
            map.getLayers().forEach((layer) => {
                if (layer instanceof VectorLayer && layer.getClassName() === className) {
                    map.removeLayer(layer);
                }
            });
            const overlayContent = document.getElementById("overlay-content");
            overlayContent.style.display = 'none'
        }
    };

    const displayPolylineRoute = (routes) => {
        removeVectorLayerByClassName('polyline_route');
        removeVectorLayerByClassName('start-feature-vector-layer');
        const polyline = routes.overviewPolyline
        const startPoint = [routes.snappedWaypoints[0].lng, routes.snappedWaypoints[0].lat]
        const routeFormat = new Polyline();
        const route = routeFormat.readGeometry(polyline);
        const vectorSource = new VectorSource({
            features: [new Feature(route)],
        });
        const vectorLayer = new VectorLayer({
            source: vectorSource,
            style: new Style({
                stroke: new Stroke({
                    color: 'rgb(34,72,253)',
                    width: 5,
                }),
            }),
            className: 'polyline_route',
            zIndex: 99
        });
        console.log('okokok')
        map.addLayer(vectorLayer);


        const startFeatureMarker = new Feature({
            type: 'icon',
            geometry: new Point(startPoint),
        });
        const startVectorLayer = new VectorLayer({
            source: new VectorSource({
                features: [startFeatureMarker],
            }),
            className: "start-feature-vector-layer",
            zIndex: 102,
            style: new Style({
                image: new Icon({
                    src: require("../img/red-dot.png"),
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'pixels',
                    anchor: [0.5, 28],
                    scale: [0.5, 0.5],
                }),
            }),
        });
        map.addLayer(startVectorLayer);



        const routeExtent = route.getExtent();
        const extent = [
            routeExtent[0] - 0.01, // Điều chỉnh giảm extent theo ý muốn
            routeExtent[1] - 0.003,
            routeExtent[2] + 0.003,
            routeExtent[3] + 0.003,
        ];
        map.getView().fit(extent, {
            size: map.getSize(),
            duration: 1000,
        });
        console.log('okokok', route.getExtent())
        console.log('okokok', extent)
    }

    useEffect(() => {
        if (route && map) {
            displayPolylineRoute(route)
        } else if(!route && map){
            removeVectorLayerByClassName('polyline_route');
            removeVectorLayerByClassName('start-feature-vector-layer');    
        }
    }, [map, route])

    // useEffect(() => {
    //     if (map) {

    //         console.log(' decodePolyline', map.getLayers())
    //     }
    // }, [map])

    const reloadData = () => {
        refreshData && refreshData();
        const overlayContent = document.getElementById("overlay-content1");
        overlayContent.style.display = 'none'
    }

    useEffect(() => {
        const defaultBaseMapUrl =
            props.defaultBaseMapUrl ||
            "https://mt.google.com/vt/lyrs=y&x={x}&y={y}&z={z}";
        const centerDefault = props.defaultCenter || config.defaultMapCenter;
        const defaultZoom = props.defaultZoom || 11;
        const defaultListLayer = props.listLayer || [];
        const defaultExtend = props.extent || [
            config.defaultLeftCornerLng,
            config.defaultLeftCornerLat,
            config.defaultRightCornerLng,
            config.defaultRightCornerLat,
        ];

        const DefaultLayers = [
            new TileLayer({
                source: new XYZ({
                    url: defaultBaseMapUrl,
                    maxZoom: 20,
                    crossOrigin: "anonymous",
                }),
            }),
        ];
        const layerDefault = [];

        if (!isAddress) {
            baseLayer.map((layer) => {
                DefaultLayers.push(
                    new TileLayer({
                        visible: layer.is_check,
                        zIndex: layer.z_index,
                        minZoom: 9,
                        maxZoom: 20,
                        className: layer.table,
                        source: new TileWMS({
                            url: layer.wms,
                            params: {
                                FORMAT: "image/png",
                                VERSION: "1.1.0",
                                STYLES: "",
                                LAYERS: `${config.WorkSpace}:${layer.table}`,
                            },
                            tileSize: 512,
                            crossOrigin: 'anonymous'
                        }),
                    })
                );
            });
        }

        const layerImage = new TileLayer({
            visible: true,
            zIndex: 1,
            minZoom: 9,
            maxZoom: 20,
            source: new TileWMS({
                url: `https://geo.cgis.asia/geoserver/pleiku/wms/`,
                params: {
                    LAYERS: `qhdongtrieu:reflection_the_scene_pending`,
                    LayerId: 1,
                    FORMAT: "image/png",
                    VERSION: "1.1.0",
                    'TILED': true,
                },
                tileSize: 512,
                crossOrigin: 'anonymous'
            }),
            className: `layer_reflection_the_scene_pending`,
        });
        DefaultLayers.push(layerImage);

        // defaultListLayer.map((layerData) => {
        //     const layerImage = new TileLayer({
        //         visible: layerData.is_check,
        //         zIndex: layerData.z_index,
        //         minZoom: layerData.min_zoom,
        //         maxZoom: layerData.max_zoom,
        //         source: new TileWMS({
        //             url: layerData.wms,
        //             params: {
        //                 LAYERS: `qhdongtrieu:${layerData.table}`,
        //                 LayerId: layerData.id,
        //                 FORMAT: "image/png",
        //                 VERSION: "1.1.0",
        //                 'TILED': true,
        //             },
        //             tileSize: 512,
        //             crossOrigin: 'anonymous'
        //         }),
        //         className: `layer-id-${layerData.id}`,
        //     });
        //     layerDefault.push(layerImage);
        // })

        // Init map
        const overlay = new Overlay({
            element: document.getElementById("overlay-popup"),
            autoPan: {
                animation: {
                    duration: 250,
                },
            },
        });
        if (!isAddress) overlayRef.current = overlay;

        var mousePositionControl = new MousePosition({
            coordinateFormat: createStringXY(4),
            projection: "EPSG:4326",
            className: "custom-mouse-position",
            target: document.getElementById("mouse-position"),
            undefinedHTML: "&nbsp;",
        });
        var initialMap = new Map({
            controls: defaultControls().extend([new FullScreen(), mousePositionControl]),
            target: mapRef.current,
            overlays: [overlay],
            layers: DefaultLayers,
            view: new View({
                projection: "EPSG:4326",
                center: centerDefault,
                extent: defaultExtend,
                zoom: defaultZoom,
                minZoom: 5,
                maxZoom: 20,
            }),
        });

        setMap(initialMap);
        mapRef.current = initialMap;
        functionHandleHover(initialMap, true);
        // functionHandleClick(initialMap, true, overlayRef.current);
        if (!isAddress) {
            // functionHandleClickFeature(initialMap, true, overlayRef.current);
            functionClickShowOverLay(initialMap, overlayRef.current)
        }
        props.CreateOpenlayerController({
            functionClickShowOverLay: functionClickShowOverLay,
        })
        return () => {
            initialMap.setTarget(null);
        };

    }, []);

    useEffect(() => {
        const lat = parseFloat(queryParams.get("latitude"));
        const lng = parseFloat(queryParams.get("longitude"));
        if (lat && lng && map && overlayRef) {
            console.log(overlayRef)
            console.log('map', map)
            const myTimeout = setTimeout(() => functionClickShowTooltip(map, null, overlayRef.current, [lng + 0.0001, lat + + 0.0003]), 2000);
            console.log('coordinate', [lng, lat]);
            console.log('pixel', map.getPixelFromCoordinate([108.02893545913896, 13.952799604428874]))
        } else {
            const buttonClose = document.getElementById("overlay_close");
            if (buttonClose) buttonClose.click();
        }
    }, [location.search, map])

    return (
        <>
            <div
                ref={mapRef}
                className='map'
                style={{ width: '100%', height: '100%' }}
                onClick={onClick ? functionClickViewCoordListener : null}
            >
            </div>
            <div id="mouse-position"></div>
            {!isAddress && (
                <div className={`map_controller ${!showSidebar ? "full" : ""}`}>
                    <MapControllerView
                        isPending={isPending}
                        addAddress={addAddress}
                        handleZoomIn={handleZoomIn}
                        handleZoomOut={handleZoomOut}
                        handleViewFullScreen={handleViewFullScreen}
                        handleReset={handleReset}
                        changeBaseMap={changeBaseMap}
                        viewCurrentLocaltion={viewCurrentLocaltion}
                        toggleDisplayLayer={toggleDisplayLayer}
                    />
                </div>
            )}
            <div id="tooltip-openlayer"></div>
            <div id="overlay-popup">
                <div id="overlay_close">x</div>
                <div id="overlay-content1" hidden={isAddress}></div>
                <div id="overlay-content" hidden={isAddress}></div>
            </div>
            <div id="overlay_route"></div>
            <HandleReflectView
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                pendingId={pendingId}
                reloadData={refreshData}
            />
        </>
    )
}
const mapStateToProps = (state) => ({

});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            CreateOpenlayerController: OpenlayerStore.CreateOpenlayerController,
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(OpenLayerView);
