import React, { useState, useRef, useEffect } from "react";
import AsyncSelect from 'react-select/async';
import PaginationView from "../../components/pagination/pagination.view";
import SearchRemoteSensing from "../../components/search/searchRemoteSensing.view";
//---Mui
import { IconButton, Tooltip } from "@material-ui/core";
import TuneIcon from "@material-ui/icons/Tune";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import ListRemoteSensing from "./list.view";
import MapController from "../map-controller/map-controller.view";
import FormRemoteSensing from "../../components/form/form-remote-sensing.view";
import AddShapeFileView from "../../components/form/add-shape-file.view";
import Draggable from 'react-draggable';
import { useMediaQuery } from '@material-ui/core';

import "./sidebar.scss";

function SidebarRemoteSensing(props) {
    const { show, setShow, data, pagination, getRoute, route, currDestination, overlayRef, mapRef } = props;
    const elementRef = useRef(null);
    const matches = useMediaQuery('(min-width:600px)');

    const [showForm, setShowForm] = useState(false);
    const [isCreateNew, setIsCreateNew] = useState();
    const [widthDefault, setWidthDefault] = useState(450);
    const [width, setWidth] = useState(330);
    const handleShowhide = () => {
        const element = elementRef.current;
        setShow(!show);
        if (show) {
            element.style.left = `-${width}px`;
            // document.getElementById("sidebar_rs").classList.add('show_');
        } else {
            element.style.left = 0;
            // document.getElementById("sidebar_rs").classList.remove('show_');
        }
    }

    useEffect(() => {
        setWidth(matches ? 450 : 330)
        setWidthDefault(matches ? 450 : 330)
    }, [matches])

    const openFormCreate = () => {
        setShowForm(true);
        setIsCreateNew(true);
    }

    const onStop = (event, ui) => {
        setWidth((width + ui.x < widthDefault) ? widthDefault : (width + ui.x));
    };

    return (
        <div ref={elementRef} id="sidebar_rs" className="sidebar_rs" style={{ width: `${width}px` }}>
            <Draggable
                axis="x"
                defaultPosition={{ x: 0, y: 0 }}
                position={{ x: 0, y: 0 }}
                onStop={onStop}
            >
                <div className={`sidebar_resize`}>&#187;</div>
            </Draggable>
            <div className="onhide_button" onClick={() => handleShowhide()}>
                <ChevronLeftIcon fontSize="medium" hidden={!show} />
                <ChevronRightIcon fontSize="medium" hidden={show} />
            </div>
            <div className="search_rs">
                <SearchRemoteSensing hasCommune hasStatus />
                {/* <AsyncSelect
                    className='search_input_select'
                    placeholder='Nhập địa chỉ tra cứu'
                    isLoading={false}
                    // loadOptions={loadOptions}
                    // onChange={data => {
                    //     handleChoose(data);
                    // }}
                    components={{ DropdownIndicator: null, LoadingIndicator: null, ClearIndicator: null }}
                    noOptionsMessage={() => 'Nhập địa chỉ để tìm kiếm'}
                /> */}
            </div>
            <hr className="sidebar_" />
            <div className="rs_content">
                {/* <div className="region_rs">
                    <div className="title">Địa phận</div>
                    <div className="name">Phường Thắng Lợi, Thành phố Pleiku</div>
                </div> */}
                <hr className="sidebar_" />
                <div className="list_rs">
                    <div className="title_">
                        <div className="title">Biến động</div>
                        <div className="sb_btn">
                            {/* <Tooltip title="Thêm mới" className="arrow_left">
                                <IconButton onClick={openFormCreate} >
                                    <AddCircleOutlineIcon fontSize="medium" />
                                </IconButton>
                            </Tooltip> */}
                            {/* <Tooltip title="Tùy chỉnh" className="arrow_left">
                                <IconButton>
                                    <TuneIcon fontSize="medium" />
                                </IconButton>
                            </Tooltip> */}
                        </div>
                    </div>
                    <ListRemoteSensing
                        data={data}
                        mapRef={mapRef}
                        overlayRef={overlayRef}
                        getRoute={getRoute}
                        route={route}
                        currDestination={currDestination}
                    />
                    <div className="pagination">
                        <PaginationView
                            showFirstLastButton
                            pageCount={pagination.pageCount}
                            totalRecords={pagination.totalItemCount}
                            pageLimit={pagination.pageSize}
                            currentPage={pagination.pageIndex}
                        />
                    </div>
                </div>
            </div>
            {showForm && (
                <AddShapeFileView
                    isOpen={showForm}
                    onClose={() => setShowForm(false)}
                />
            )}
        </div>
    )
}

export default SidebarRemoteSensing;