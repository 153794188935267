import React, { useEffect, useMemo, useState } from "react";
import AsyncSelect from 'react-select/async';
import ListResult from "./list-result/list-result.view";
import DetailResult from "./detail-result/detail-result.view";
import SearchRemoteSensing from "../components/search/searchRemoteSensing.view";
import * as rsAction from "../../../redux/store/remote-sensing/remote-sensing.store";
import * as appActions from "../../../core/app.store";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useLocation } from 'react-router-dom';
import { queryParamsToObject } from "../helper/helper";
import RefreshIcon from "@material-ui/icons/Refresh";
import { Fab, Tooltip } from "@material-ui/core";
import { DomainAdminSide } from "../../../utils/configuration";
import { formattedDate } from "../helper/helper";
import { format, parse } from 'date-fns';
import "./history-remote-sensing.scss";

function HistoryRemoteSensing(props) {
    const { showLoading } = props;
    const location = useLocation();
    const url = location.pathname;
    const queryParams = new URLSearchParams(location.search);

    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({});
    const [dataHistory, setDataHistory] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(queryParams.get('pageIndex') || 1);
    const [communeIdSelected, setCommuneIdSelected] = useState(queryParams.get('communeId'));
    const [streetNameSelected, setStreetNameSelected] = useState(queryParams.get('streetName'));
    const [remoteSensingSelected, setRemoteSensingSelected] = useState();

    const GetList = (params) => {
        showLoading(true);
        rsAction.GetListAll(params).then(res => {
            if (res && res.content) {
                const { items, pageCount, pageIndex, pageSize, totalItemCount } = res.content;
                setData(items);
                setPagination({ ...pagination, pageCount, pageIndex, pageSize, totalItemCount });
            }
            showLoading(false);
        }).catch(err => showLoading(false))
    }

    const GetListHistory = (params) => {
        showLoading(true);
        rsAction.GetListHistory(params).then(res => {
            if (res && res.content) {
                setDataHistory(res.content.items)
            }
            showLoading(false);
        }).catch(err => showLoading(false))
    }

    useEffect(() => {
        let params = {
            pageSize: queryParams.get('pageSize') || 10,
            pageIndex: queryParams.get('pageIndex') || 1,
            communeId: queryParams.get('communeId') || null,
            streetName: queryParams.get('streetName') || null,
            fromDate: formattedDate(queryParams.get("fromDate")),
            toDate: formattedDate(queryParams.get("toDate")),
            statusId: queryParams.get("statusId"),
        }
        GetList(params);
    }, [location.search])

    const reloadData = () => {
        if (remoteSensingSelected) {
            let params = {
                pageSize: pageSize,
                pageIndex: pageIndex,
                reflectTheSceneId: remoteSensingSelected.id,
            }
            GetListHistory(params);
        }
    }

    useEffect(() => {
        if (remoteSensingSelected) {
            let params = {
                pageSize: pageSize,
                pageIndex: pageIndex,
                reflectTheSceneId: remoteSensingSelected.id,
            }
            GetListHistory(params);
        }
    }, [remoteSensingSelected])

    const handleRefresh = () => {
        window.location.replace(DomainAdminSide + url)
        // history.push({
        //     pathname: url
        // })
    }

    return (
        <div>
            <div className="page_header">
                <div className="page_title">
                    <h3>Lịch sử biến động</h3>
                </div>
            </div>
            <div className="container mw_1500 history_rs">
                <div className="search_block">
                    <SearchRemoteSensing hasFromToDate hasCommune hasStatus/>
                </div>
                <div className="row" style={{ marginLeft: 0 }}>
                    <div className="col-md-4 list_block">
                        <div className="search_result">
                            <div className="name_result">
                                <p>Kết quả tìm kiếm</p>
                                {/* <div className="refresh_btn">
                                    <Tooltip title="Làm mới">
                                        <Fab
                                            color="primary"
                                            aria-label="filter"
                                            size="small"
                                            onClick={handleRefresh}
                                            className="mt-2 mr-3"
                                        >
                                            <RefreshIcon />
                                        </Fab>
                                    </Tooltip>
                                </div> */}
                            </div>
                            <div className="title_">
                                Biến động
                            </div>
                            <div className="list_rs">
                                <ListResult
                                    data={data}
                                    remoteSensingSelected={remoteSensingSelected}
                                    setRemoteSensingSelected={setRemoteSensingSelected}
                                    pagination={pagination}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="list_detail_block">
                            <DetailResult
                                itemSelected={remoteSensingSelected}
                                dataHistory={dataHistory}
                                reloadData={reloadData}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(HistoryRemoteSensing);