import avatar1 from "../map-remote-sensing/img/rs1.png";
import avatar2 from "../map-remote-sensing/img/rs2.png";

export const convertAddress = (address) => {
  if (address) {
    let result = address?.name + ", đường" +
      address?.streetName + ", " +
      address?.communeName;
    return result
  } else {
    return
  }
};

export const formatAddress = (address) => {
  let result = address?.fullAddressName + ", đường " +
    address?.streetName + ", " +
    address?.communeName;
  return result
};

const today = new Date();
const fromDate = new Date(
  today.getUTCFullYear(),
  today.getUTCMonth(),
  today.getDate(),
  today.getHours(),
  today.getMinutes(),
  0,
  0
);

export const rsHistoryItem = {
  content: '',
  date: fromDate,
  files: []
};

export function queryParamsToObject(url) {
  const params = new URLSearchParams(url);
  const paramsObject = {};

  for (const [key, value] of params) {
    // If the parameter key already exists in the object, create an array to store multiple values.
    if (paramsObject.hasOwnProperty(key)) {
      if (Array.isArray(paramsObject[key])) {
        paramsObject[key].push(value);
      } else {
        paramsObject[key] = [paramsObject[key], value];
      }
    } else {
      paramsObject[key] = value;
    }
  }

  return paramsObject;
}

export const dataFeaturesFake = [
  {
    id: 1,
    name: 'biến động 1',
    content: 'biến động 1 content',
    longtitude: '108.006680',
    latitude: '13.965816',
    statusId: 1,
    statusName: 'mới',
    avatar: avatar1,
  },
  {
    id: 2,
    name: 'biến động 2',
    content: 'biến động 2 content',
    longtitude: '108.031407',
    latitude: '13.965137',
    statusId: 2,
    statusName: 'đang xử lý',
    avatar: avatar2,
  }
]

export const formattedDate = (date) => {
  if (date) {
    const [day, month, year] = date.split('-');
    return `${month}-${day}-${year}`
  } else return null
}

export const formatCoordinate = (value) => {
  return parseFloat(value).toFixed(5);
}

export const decodePolyline = (polyline) => {
  var index = 0,
    lat = 0,
    lng = 0,
    coordinates = [];

  while (index < polyline.length) {
    var shift = 0,
      result = 0;

    do {
      var byte = polyline.charCodeAt(index++) - 63;
      result |= (byte & 0x1f) << shift;
      shift += 5;
    } while (byte >= 0x20);

    var deltaLat = (result & 1) ? ~(result >> 1) : (result >> 1);
    lat += deltaLat;

    shift = 0;
    result = 0;

    do {
      var byte = polyline.charCodeAt(index++) - 63;
      result |= (byte & 0x1f) << shift;
      shift += 5;
    } while (byte >= 0x20);

    var deltaLng = (result & 1) ? ~(result >> 1) : (result >> 1);
    lng += deltaLng;

    coordinates.push([lat * 1e-5, lng * 1e-5]);
  }

  return coordinates;
}

export const GetListYear = () => {
  const date = new Date();
  const today = new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getDate(),
  );
  let data = [];
  for (let i = 0; i <= (today.getFullYear() - 2023); i++) {
    data.push({
      value: today.getFullYear() - i,
      label: `Năm ${today.getFullYear() - i}`,
      fromDate: new Date(
        date.getUTCFullYear() - i,
        0,
        1,
      ),
      toDate: new Date(
        date.getUTCFullYear() - i,
        11,
        31,
      )
    })
  }
  return data
}

export const GetListMoth = (year) => {
  const date = new Date();
  const today = new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getDate(),
  );
  let data = [];
  const months = year < today.getFullYear() ? 11 : today.getMonth();
  for (let i = 0; i <= months; i++) {
    data.push({
      value: i + 1,
      label: `Tháng ${i + 1}`,
      fromDate: new Date(
        year,
        i,
        1,
      ),
      toDate: new Date(
        year,
        i,
        getDaysByMonthAndYear(i + 1, year),
      )
    })
  }
  return data
}

export const GetListQuarter = (year) => {
  const date = new Date();
  const today = new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getDate(),
  );
  let data = [];
  if (year < today.getFullYear()) {
    data = GetListQuarterByYear(year, 11);
  } else {
    data = GetListQuarterByYear(year, today.getMonth());
  }
  return data;
}

const GetListQuarterByYear = (year, months) => {
  let data = []
  const firstQuarter = {
    value: 1,
    label: 'Quý 1',
    fromDate: CreateDate(year, 0, 1),
    toDate: CreateDate(year, 2, 31)
  };
  const secondQuarter = {
    value: 2,
    label: 'Quý 2',
    fromDate: CreateDate(year, 4, 1),
    toDate: CreateDate(year, 5, 30)
  };
  const thirdQuarter = {
    value: 3,
    label: 'Quý 3',
    fromDate: CreateDate(year, 7, 1),
    toDate: CreateDate(year, 8, 30)
  }
  const fouthQuarter = {
    value: 4,
    label: 'Quý 4',
    fromDate: CreateDate(year, 10, 1),
    toDate: CreateDate(year, 11, 31)
  }
  switch (months) {
    case 0: case 1: case 2:
      data = [firstQuarter];
      break;
    case 3: case 4: case 5:
      data = [firstQuarter, secondQuarter];
      break;
    case 6: case 7: case 8:
      data = [firstQuarter, secondQuarter, thirdQuarter];
      break;
    case 9: case 10: case 11:
      data = [firstQuarter, secondQuarter, thirdQuarter, fouthQuarter];
      break;
    default:
      return
  }
  return data;
}

const CreateDate = (year, month, day) => {
  return new Date(year, month, day)
}
const getDaysByMonthAndYear = (month, year) => {
  switch (month) {
    case 1: case 3: case 5: case 7: case 8: case 10: case 12:
      return 31
    case 4: case 6: case 9: case 11:
      return 30
    case 2:
      return year % 4 === 0 ? 29 : 28
    default:
      return 0
  }
}

