import React, { useState } from "react";
import { listRS } from "../../components/model";
import dateformat from "dateformat";
import PaginationView from "../../components/pagination/pagination.view";
import { formatCoordinate } from "../../helper/helper";

function ListResult(props) {
    const { data, pagination, remoteSensingSelected, setRemoteSensingSelected } = props;
    const [isActive, setIsActive] = useState(0);

    return (
        <div className="list_rs_container">
            <div >
                {data && data.length > 0 && data.map((item, index) => {
                    return (
                        <div key={index} onClick={() => setRemoteSensingSelected(item)}>
                            <div className={`rs_item ${remoteSensingSelected?.id === item.id ? "active" : ""}`}>
                                <div className="rs_date">
                                    {dateformat(item.createdDate, "dd/mm/yyyy")}
                                </div>
                                <div className="rs_address">
                                    <div className="address">{item.title}</div>
                                    <div className="coord">{formatCoordinate(item.latitude)}, {formatCoordinate(item.longitude)}</div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className="pagination">
                <PaginationView
                    showFirstLastButton
                    pageCount={pagination.pageCount}
                    totalRecords={pagination.totalItemCount}
                    pageLimit={pagination.pageSize}
                    currentPage={pagination.pageIndex}
                />
            </div>
        </div>
    )
}

export default ListResult;