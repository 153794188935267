import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActions from "../../../../core/app.store";
import ShowNotification from "../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../utils/configuration";
import * as viVN from "../../../../language/vi-VN.json";

import * as communeAction from "../../../../redux/store/commune-management/commune.store";
import * as streetAction from "../../../../redux/store/street-management/street.store";
import { convertAddress } from "../../helper/helper";
import Autocomplete from "@material-ui/lab/Autocomplete";
import RefreshIcon from "@material-ui/icons/Refresh";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import DateRangeIcon from "@material-ui/icons/DateRange";
import history from "../../../../common/history";
import { useLocation } from 'react-router-dom';
import dateformat from "dateformat";
import moment from 'moment';
import { DomainAdminSide } from "../../../../utils/configuration";

import {
    TextField,
    Fab,
    Tooltip,
} from "@material-ui/core";

const formatDateParams = (input) => {
    const [day, month, year] = input.split('-');
    const formattedDate = new Date(`${year}-${month}-${day}T00:00:00Z`)

    const options = {
        weekday: 'short',
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZoneName: 'short',
        timeZone: 'Asia/Bangkok', // Chọn múi giờ cho Đông Dương
    };
    return formattedDate.toLocaleString('en-US', options);
}

var query = null;

function SearchRemoteSensing(props) {
    const { handleChoose, showLoading, hasFromToDate, hasCommune, hasStatus } = props;
    const location = useLocation();
    const url = location.pathname;
    const queryParams = new URLSearchParams(location.search);
    const date = new Date();
    const today = new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        0,
        0
    );
    const fromDateDefault = queryParams.get('fromDate') ?
        formatDateParams(queryParams.get('fromDate')) :
        new Date(2023, 0, 1);
    const toDateDefault = queryParams.get('toDate') ?
        formatDateParams(queryParams.get('toDate')) :
        today;
    const defaultStatusValue = queryParams.get("status") === 'false' ? 1 : queryParams.get("status") === 'true' ? 2 : 0;
    const [communeModels, setCommuneModels] = useState([]);
    const [curCommune, setCurCommune] = useState({ id: 0, name: 'Chọn xã - phường' });
    const [streetModels, setStreetModels] = useState([]);
    const [curStreet, setCurStreet] = useState({ id: 0, name: 'Chọn đường' });
    const [fromDate, setFromDate] = useState(fromDateDefault);
    const [toDate, setToDate] = useState(toDateDefault);
    const [validationError, setValidationError] = useState('');

    useEffect(() => {
        if (hasCommune) {
            GetCommuneByDistrictId(36);
        }
    }, []);

    useEffect(() => {
        if (curCommune.id) {
            GetStreetByCommuneId(curCommune.id);
            setCurStreet({ id: 0, name: 'Chọn đường' });
            queryParams.set('communeId', curCommune.id);
            queryParams.delete('streetId');
            queryParams.delete('streetName');
            history.push({
                search: queryParams.toString(),
            })
        }
    }, [curCommune]);

    useEffect(() => {
        if (curCommune.id) {
            curStreet.id && queryParams.set('streetId', curStreet.id);
            curStreet.id && queryParams.set('streetName', curStreet.name);
            history.push({
                search: queryParams.toString(),
            })
        }
    }, [curStreet])

    useEffect(() => {

    }, [location.search])


    const GetCommuneByDistrictId = (id) => {
        showLoading(true);
        communeAction
            .GetLookupCommune(id)
            .then((res) => {
                if (res && res.content) {
                    setCommuneModels(res.content);
                    if (queryParams.get('communeId')) {
                        res.content.find(item => {
                            if (item.id === parseInt(queryParams.get('communeId'))) {
                                setCurCommune(item)
                            }
                        })
                    }
                    showLoading(false);
                }
            })
            .catch((err) => {
                err &&
                    err.errorType &&
                    ShowNotification(
                        viVN.Errors[err.errorType],
                        NotificationMessageType.Error
                    );
                showLoading(false);
            });
    };

    const GetStreetByCommuneId = (id) => {
        showLoading(true);
        streetAction
            .GetLookupStreet(id)
            .then((res) => {
                if (res && res.content) {
                    setStreetModels(res.content);
                    if (queryParams.get('streetId')) {
                        res.content.find(item => {
                            if (item.id === parseInt(queryParams.get('streetId'))) {
                                setCurStreet(item)
                            }
                        })
                    }
                    showLoading(false);
                }
            })
            .catch((err) => {
                err &&
                    err.errorType &&
                    ShowNotification(
                        viVN.Errors[err.errorType],
                        NotificationMessageType.Error
                    );
                showLoading(false);
            });
    };

    const handleFromDateChange = (date) => {
        setFromDate(date)
        if (moment(date, 'DD-MM-YYYY', true).isValid()) {
            if (isDateRangeValid(date, toDate)) {
                queryParams.set('fromDate', dateformat(date, "dd-mm-yyyy"));
                queryParams.set('toDate', dateformat(toDate, "dd-mm-yyyy"));
                pushParams();
            }
        }
    };

    const handleToDateChange = (date) => {
        setToDate(date)
        if (moment(date, 'DD-MM-YYYY', true).isValid()) {
            if (isDateRangeValid(fromDate, date)) {
                queryParams.set('fromDate', dateformat(fromDate, "dd-mm-yyyy"));
                queryParams.set('toDate', dateformat(date, "dd-mm-yyyy"));
                pushParams();
            }
        }
    };

    const handleChangeStatus = (value) => {
        const status = parseInt(value);
        switch (status) {
            case 0:
                queryParams.delete('statusId')
                break;
            case 2:
                queryParams.set('statusId', 2)
                break;
            case 3:
                queryParams.set('statusId', 3)
                break;
            default:
                queryParams.delete('statusId')
        }
        pushParams();
    }

    const handleRefresh = () => {
        window.location.replace(DomainAdminSide + url)
        // history.push({
        //     pathname: url
        // })
    }


    const isDateRangeValid = (startDate, endDate) => {
        // console.log('fromdate', startDate)
        // console.log('todate', endDate)

        if (startDate && endDate && startDate > endDate) {
            setValidationError('Ngày bắt đầu phải nhỏ hơn hoặc bằng ngày kết thúc');
            return false
        } else {
            setValidationError('');
            return true
        }
    }

    const pushParams = () => {
        history.push({
            search: queryParams.toString(),
        })
    }

    return (
        <div>
            <div className="form_search_rs">
                <div className="row">
                    {communeModels && communeModels.length > 0 && (
                        <div className={`form-item mb-3 ${hasFromToDate ? 'col-md-2 form-item' : 'col-6'}`}>
                            <Autocomplete
                                options={communeModels}
                                getOptionLabel={(option) =>
                                    typeof option === "string" ? option : option.name
                                }
                                value={curCommune}
                                onChange={(event, newValue) => {
                                    setCurCommune(newValue);
                                }}
                                disableClearable={true}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name="communeId"
                                        variant="outlined"
                                        size="small"
                                    />
                                )}
                            />
                        </div>
                    )}
                    {streetModels && streetModels.length > 0 && (
                        <div className={`form-item ${hasFromToDate ? 'col-md-2 form-item' : 'col-6'}`}>
                            <Autocomplete
                                options={streetModels}
                                getOptionLabel={(option) =>
                                    typeof option === "string" ? option : option.name
                                }
                                value={curStreet}
                                onChange={(event, newValue) => {
                                    setCurStreet(newValue);
                                }}
                                disableClearable={true}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name="streetId"
                                        variant="outlined"
                                        size="small"
                                    />
                                )}
                            />
                        </div>
                    )}
                    {hasFromToDate && (
                        <>
                            <div className="col-lg-2">
                                <div className="filter_item">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            label="Từ ngày"
                                            id="fromDate"
                                            name="fromDate"
                                            onChange={(date) => date && handleFromDateChange(date)}
                                            value={fromDate}
                                            format="dd/MM/yyyy"
                                            fullWidth
                                            error={Boolean(validationError)}
                                            helperText={validationError}
                                            showTodayButton={true}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    fullWidth
                                                    margin="normal"
                                                    required
                                                    InputProps={{
                                                        endAdornment: <DateRangeIcon />,
                                                    }}
                                                />
                                            )}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="filter_item">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            label="Đến ngày"
                                            id="toDate"
                                            name="toDate"
                                            onChange={(date) => date && handleToDateChange(date)}
                                            value={toDate}
                                            format="dd/MM/yyyy"
                                            fullWidth
                                            error={Boolean(validationError)}
                                            helperText={validationError}
                                            showTodayButton={true}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    fullWidth
                                                    margin="normal"
                                                    required
                                                    InputProps={{
                                                        endAdornment: <DateRangeIcon />,
                                                    }}
                                                />
                                            )}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                            </div>
                        </>
                    )}
                    {hasStatus && (
                        <div className={`${hasFromToDate ? 'col-md-2 form-item' : 'col-6'}`}>
                                <select
                                    class="form-select"
                                    aria-label="Default select example"
                                    onChange={e => handleChangeStatus(e.target.value)}
                                    defaultValue={defaultStatusValue}
                                >
                                    <option selected value={0}>---Trạng thái---</option>
                                    <option value={3}>Đã xử lý</option>
                                    <option value={2}>Đang xử lý</option>
                                </select>
                        </div>
                    )}
                    <div className={`${hasFromToDate ? 'col-md-2 form-item' : 'col-6'}`}>
                        <div className="">
                            <Tooltip title="Làm mới">
                                <Fab
                                    color="primary"
                                    aria-label="filter"
                                    size="small"
                                    onClick={handleRefresh}
                                    className={`mr-3 ${!hasFromToDate ? 'mb-3' : ''}`}
                                >
                                    <RefreshIcon />
                                </Fab>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(SearchRemoteSensing);