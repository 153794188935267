import React, { useEffect, useState } from "react";
import * as rsAction from "../../../../redux/store/remote-sensing/remote-sensing.store";
import * as appActions from "../../../../core/app.store";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useLocation } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@material-ui/core";
import TableSortLabel from '@material-ui/core/TableSortLabel';
import dateformat from "dateformat";
import { makeStyles } from "@material-ui/core/styles";

import EditIcon from "@material-ui/icons/Edit";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import PaginationView from "../../components/pagination/pagination.view";
import DeleteDialog from "../../components/form/delete-dialog.view";
import EditDialog from "./edit/edit.view";

// import "./history-remote-sensing.scss";
import ShowNotification from "../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../utils/configuration";
import * as viVN from "../../../../language/vi-VN.json";
import apiConfig from "../../../../api/api-config";
import { saveAs } from "file-saver";
import moment from "moment";
import history from "../../../../common/history";
import HandleReflect from "../../components/form/form-handle.view";
import FilterReflect from "./filter.view";
import { formattedDate } from "../../helper/helper";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    paper: {
        width: "100%",
    },
    tableContainer: {
        maxHeight: window.outerHeight - 365,
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
    appBar: {
        position: "relative",
        backgroundColor: "#00923F",
    },
    title: {
        marginLeft: theme.spacing(0),
        flex: 1,
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

function RemoteSensingManage(props) {
    const { showLoading } = props;
    const location = useLocation();
    const { state } = location;
    const queryParams = new URLSearchParams(location.search);
    const classes = useStyles();

    const [data, setData] = useState([]);
    const [dataSelected, setDataSelected] = useState();
    const [reflectSelect, setReflectSelect] = useState();
    const pageSize = 10;
    const [pageIndex, setPageIndex] = useState(1);
    const [orderBy, setOrderBy] = useState('modifiedDate');
    const [order, setOrder] = useState('desc');
    const [pagination, setPagination] = useState({});
    const [isOpen, setIsOpen] = useState(false);
    const [pendingId, setPendingId] = useState();
    const [isOpenDelete, setIsOpenDelete] = useState(false);
    const [isOpenFormHandle, setIsOpenFormHandle] = useState(false);

    const GetList = (params) => {
        showLoading(true);
        rsAction.GetListAll(params).then(res => {
            if (res && res.content) {
                const { items, pageCount, pageIndex, pageSize, totalItemCount } = res.content;
                setData(items);
                setPagination({ ...pagination, pageCount, pageIndex, pageSize, totalItemCount });
            }
            showLoading(false);
        }).catch(err => showLoading(false))
    }

    const GetDetail = (id) => {
        showLoading(true);
        rsAction.GetDetailReflectTheScene(id).then(res => {
            if (res && res.content) {
                setDataSelected(res.content)
                showLoading(false)
            }
        }).catch(err => showLoading(false))
    }

    const handleDelete = () => {
        showLoading(true);
        rsAction.DeleteReflectTheScene(pendingId).then(res => {
            if (res) {
                ShowNotification("Xóa thành công", NotificationMessageType.Success);
                setIsOpenDelete(false);
                reloadData();
            }
            showLoading(false);
        }).catch(err => {
            err &&
                err.errorType &&
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            showLoading(false);
        })
    }

    const handleOpenDelete = (id) => {
        setIsOpenDelete(true);
        setPendingId(id);
    }

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    const onRequestSort = (event, property) => {
        console.log(event, property)
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        let sort = isAsc ? 'desc' : 'asc';
        let sorting = property + ' ' + sort;
        queryParams.set("sorting", sorting);
        history.push({
            search: queryParams.toString(),
        })
    }

    const getParamsFromQuery = () => {
        return {
            pageSize: queryParams.get("pageSize") || pageSize,
            pageIndex: queryParams.get("pageIndex") || 1,
            search: queryParams.get("search"),
            fromDate: formattedDate(queryParams.get("fromDate")),
            toDate: formattedDate(queryParams.get("toDate")),
            communeId: queryParams.get("communeId"),
            streetName: queryParams.get("streetName"),
            statusId: queryParams.get("statusId"),
            sorting: queryParams.get("sorting"),
        }
    }

    const listTableCell = [
        // { id: 'avatar', hideSortIcon: false, isSort: true, label: 'Ảnh', width: '14%' },
        { id: 'title', hideSortIcon: false, isSort: true, label: 'Tên biến động', width: '14%' },
        { id: 'content', hideSortIcon: false, isSort: true, label: 'Nội dung', width: '30%' },
        { id: 'address', hideSortIcon: true, isSort: false, label: 'Địa chỉ', width: '25%' },
        { id: 'userHandlerId', hideSortIcon: false, isSort: true, label: 'Người xử lý', width: '30%' },
        { id: 'reflectionStatusId', hideSortIcon: false, isSort: true, label: 'Trạng thái', width: '30%' },
        { id: 'createdDate', hideSortIcon: false, isSort: true, label: 'Ngày tạo', width: '14%' },
        { id: 'modifiedDate', hideSortIcon: false, isSort: true, label: 'Ngày cập nhật', width: '14%' },
        { id: 'modifiedBy', hideSortIcon: false, isSort: true, label: 'Người cập nhật', width: '14%' },
        { id: 'action', hideSortIcon: false, isSort: true, label: '', width: '17%' },
    ]

    const reloadData = () => {
        let params = getParamsFromQuery();
        GetList(params);
    }

    const handleOpenFormHandle = (id) => {
        setPendingId(id);
        setIsOpenFormHandle(true);
    }

    useEffect(() => {
        if (state && state.resourceId) {
            console.log(state.resourceId)
            GetDetail(state.resourceId);
            history.push({
                search: queryParams.toString(),
            })
        }
    }, [state])

    useEffect(() => {
        let params = getParamsFromQuery();
        GetList(params);
    }, [location.search])

    const editAction =(data) => {
        setReflectSelect(data)
        setIsOpen(true)
    }

    const handleExport = (type) => {
        let params = { ...getParamsFromQuery(), extention: type }
        showLoading(true);
        rsAction.ExportFile(params)
            .then((result) => {
                const blob = new Blob([result], {
                    type: "application/*",
                });
                saveAs(
                    blob,
                    `Danh_sach_bien_dong_Export_(${moment(
                        new Date()
                    ).format("YYYY-MM-DD HH:mm:ss")}).xlsx`
                );
                showLoading(false);
            })
            .catch((error) => {
                showLoading(false);
            });
    }

    return (
        <div>
            <div className="page_header">
                <div className="page_title">
                    <h3>Danh sách Biến động</h3>
                </div>
            </div>
            <div className="container mw_1500 history_rs">
                <div className="page_header2">
                    <div className="page_filter_">
                        <FilterReflect exportAction={handleExport} />
                    </div>
                </div>
                <div className={classes.root}>
                    <Paper className={classes.paper}>
                        <TableContainer component={Paper}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        {listTableCell.map(item => (
                                            <TableCell
                                                key={item.id}
                                                className={
                                                    "pt-3 pb-3 text-nowrap " +
                                                    (item.id === "title" ? "MuiTableCell-freeze header_title" : "")
                                                }
                                            // width={(item.id === "title" ? "25%": "")}
                                            >
                                                <TableSortLabel
                                                    active={orderBy === item.id}
                                                    direction={orderBy === item.id ? order : 'asc'}
                                                    onClick={item.isSort ? createSortHandler(item.id) : null}
                                                    hideSortIcon={item.hideSortIcon ? true : false}>
                                                    {item.label}
                                                    {/* {orderBy === item.id ? (
                                                            <span>
                                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                            </span>
                                                        ) : null} */}
                                                </TableSortLabel>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dataSelected && (
                                        <TableRow hover className="cell-hl" tabIndex={-1} key={dataSelected.id}>
                                            {/* <TableCell>
                                            <img className="img_avatar" src={apiConfig.api + dataSelected.filePath} alt="avatar" />
                                        </TableCell> */}
                                            <TableCell className="MuiTableCell-freeze cell-hl">{dataSelected.title}</TableCell>
                                            <TableCell>{dataSelected.content}</TableCell>
                                            <TableCell>{`${dataSelected.fullAddressName}, ${dataSelected.streetName}, ${dataSelected.communeName}`}</TableCell>
                                            <TableCell>{dataSelected?.userHandlerName}</TableCell>
                                            <TableCell>{dataSelected.reflectionStatusName}</TableCell>
                                            <TableCell>{dataSelected.modifiedBy}</TableCell>
                                            <TableCell>{dateformat(dataSelected.modifiedDate, "dd-mm-yyyy")}</TableCell>

                                            <TableCell>
                                                <Tooltip hidden={dataSelected.reflectionStatusId === 3} title={dataSelected.reflectionStatusId === 2 ? 'Xử lý' : 'Đã Xử lý'}>
                                                    <IconButton
                                                        aria-label="edit"
                                                        disabled={dataSelected.reflectionStatusId === 3}
                                                        onClick={() => handleOpenFormHandle(dataSelected.id)}
                                                    >
                                                        <DragHandleIcon className={dataSelected.reflectionStatusId === 2 ? 'text-primary' : 'text-secondary'} />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Xóa">
                                                    <IconButton
                                                        aria-label="delete"
                                                        onClick={() => handleOpenDelete(dataSelected.id)}
                                                    >
                                                        <DeleteIcon className="text-danger" />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    {data && data.length > 0 && data.map((item) => {
                                        if (!item.isDelete)
                                            return (
                                                <TableRow hover tabIndex={-1} key={item.id}>
                                                    {/* <TableCell>
                                                        <img className="img_avatar" src={apiConfig.api + item.filePath} alt="avatar" />
                                                    </TableCell> */}
                                                    <TableCell className="MuiTableCell-freeze">{item.title}</TableCell>
                                                    <TableCell>{item.content}</TableCell>
                                                    <TableCell>{`${item.fullAddressName}, ${item.streetName}, ${item.communeName}`}</TableCell>
                                                    <TableCell>{item?.userHandlerName}</TableCell>
                                                    <TableCell>{item.reflectionStatusName}</TableCell>
                                                    <TableCell>{dateformat(item.createdDate, "dd-mm-yyyy")}</TableCell>
                                                    <TableCell>{dateformat(item.modifiedDate, "dd-mm-yyyy")}</TableCell>
                                                    <TableCell>{item.modifiedBy}</TableCell>

                                                    <TableCell>
                                                        <Tooltip hidden={item.reflectionStatusId === 3} title={item.reflectionStatusId === 2 ? 'Xử lý' : 'Đã Xử lý'}>
                                                            <IconButton
                                                                aria-label="edit"
                                                                disabled={item.reflectionStatusId === 3}
                                                                onClick={() => handleOpenFormHandle(item.id)}
                                                            >
                                                                <DragHandleIcon className={item.reflectionStatusId === 2 ? 'text-primary' : 'text-secondary'} />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip hidden={item.reflectionStatusId === 2} title="Sửa">
                                                            <IconButton
                                                                aria-label="edit"
                                                                onClick={() => editAction(item)}
                                                            >
                                                                <EditIcon className="text-primary" />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Xóa">
                                                            <IconButton
                                                                aria-label="delete"
                                                                onClick={() => handleOpenDelete(item.id)}
                                                            >
                                                                <DeleteIcon className="text-danger" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </div>
                <div className="float-end">
                    <PaginationView
                        showFirstLastButton
                        pageCount={pagination.pageCount}
                        totalRecords={pagination.totalItemCount}
                        pageLimit={pagination.pageSize}
                        currentPage={pagination.pageIndex}
                    />
                </div>
                {isOpenDelete && (
                    <DeleteDialog
                        isOpen={isOpenDelete}
                        onClose={() => setIsOpenDelete(false)}
                        onSuccess={handleDelete}
                        header={"Xác nhận xóa"}
                        content={"Bạn có chắc chắn muốn xóa?"}
                    />
                )}
                {isOpenFormHandle && (
                    <HandleReflect
                        isOpen={isOpenFormHandle}
                        onClose={() => setIsOpenFormHandle(false)}
                        reloadData={reloadData}
                        pendingId={pendingId}
                    />
                )}
                {isOpen && (
                    <EditDialog 
                    isOpen={isOpen}
                    onClose={() => setIsOpen(false)}
                    data={reflectSelect}
                    reloadData={reloadData}
                    />
                )}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(RemoteSensingManage);