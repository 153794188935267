import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActions from "../../../../core/app.store";
import * as addressAction from "../../../../redux/store/remote-sensing/address.store";
import OpenLayerView from "../../map-remote-sensing/map/open-layer.view";
import { DropzoneArea } from 'material-ui-dropzone'

//--- Mui
import {
    DialogActions,
    Button,
    TextField,
    DialogContent,
    DialogTitle,
    Dialog,
    Typography,
    IconButton,
    Tooltip,
    makeStyles,
    Checkbox,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import * as rsAction from "../../../../redux/store/remote-sensing/remote-sensing.store";
import FileManagement from "../../../../components/file_management/file_management";
import ShowNotification from "../../../../components/react-notifications/react-notifications";
import { NotificationMessageType, APIUrlDefault, } from "../../../../utils/configuration";
import * as viVN from "../../../../language/vi-VN.json";

import "./form-remote-sensing.scss";
import dateFormat from "dateformat";

const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

function AddReflect(props) {
    const { reloadData, isOpen, onClose, showLoading, reflectId } = props;
    const classes = useStyles();

    const { register, handleSubmit, setError, errors, clearErrors, setValue } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });

    const [files, setFiles] = useState([]);
    const [filesTemp, setFilesTemp] = useState([]);
    const [isShow, setShow] = useState(false);
    const [gallery, setGallery] = useState([]);
    const [userHandleModel, setUserHandleModel] = useState([]);
    const [userHandleSelected, setUserHandleSelected] = useState([]);

    const today = new Date();
    const fromDate = new Date(
        today.getUTCFullYear(),
        today.getUTCMonth(),
        today.getDate(),
        today.getHours(),
        today.getMinutes(),
        0,
        0
    );
    const [publishDate, setPublishDate] = useState(fromDate);

    const onSubmit = (data) => {
        console.log('data', data)
        if(gallery.length > 0) {
            let formData = new FormData();
            formData.append('Content', data.title)
            formData.append('Latitude', data.latitude)
            formData.append('Longitude', data.longitude)
            formData.append('EventDate', dateFormat(publishDate, "mm-dd-yyyy"))
            if (gallery.length > 0) {
                gallery.map(item => {
                    formData.append('galaryImages', item);
                })
            }
            showLoading(true)
            rsAction.CreatePending(formData).then(res => {
                res && ShowNotification("Thêm mới thành công", NotificationMessageType.Success);
                reloadData();
                onClose();
                showLoading(false);
            }).catch(err => {
                showLoading(false);
                ShowNotification("Xảy ra lỗi vui lòng liên hệ admin", NotificationMessageType.Error);
            });    
        } else {
            ShowNotification("Vui lòng thêm ảnh biến động", NotificationMessageType.Error, "center");
        }
    }

    const isNumber = (value) => {
        return !isNaN(value) && !isNaN(parseFloat(value));
    };

    return (
        <>
            <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth="md" className="form_rs">
                <DialogTitle disableTypography className="border-bottom">
                    <Typography variant="h6">Thêm mới biến động</Typography>
                    <IconButton
                        aria-label="close"
                        // className={classes.closeButton}
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <DialogContent className="pt-4 pb-2">
                        <div>
                            <div className="form-group">
                                <label className="text-dark fw-6">
                                    Nội dung<span className="required"></span>
                                </label>
                                <TextField
                                    name="title"
                                    error={
                                        errors.title &&
                                        (errors.title.type === "required" ||
                                            errors.title.type === "maxLength")
                                    }
                                    fullWidth
                                    type="text"
                                    className="form-control"
                                    //   inputProps={{ maxLength: 200 }}
                                    inputRef={register({ required: true, maxLength: 500 })}
                                    inputProps={{ maxLength: 500 }}
                                    onChange={(e) =>
                                        setValue("title", e.target.value)
                                    }
                                />
                                {errors.title && errors.title.type === "required" && (
                                    <span className="error">Trường này là bắt buộc</span>
                                )}
                                {errors.title && errors.title.type === "maxLength" && (
                                    <span className="error">Trường này không quá 500 ký tự</span>
                                )}
                            </div>

                            <div className="form-group">
                                <div className="row">
                                    <div className="col-md-4">
                                        <label className="text-dark">
                                            Ngày<span className="required"></span>
                                        </label>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                id="publishDate"
                                                name="publishDate"
                                                onChange={(date) => date && setPublishDate(date)}
                                                value={publishDate}
                                                format="dd/MM/yyyy"
                                                fullWidth
                                                showTodayButton={true}
                                                error={
                                                    errors.publishDate &&
                                                    errors.publishDate.type === "required"
                                                }
                                            />
                                        </MuiPickersUtilsProvider>
                                        {errors.publishDate &&
                                            errors.publishDate.type === "required" && (
                                                <span className="error">
                                                    Trường này là bắt buộc
                                                </span>
                                            )}
                                    </div>
                                    <div className="col-md-4">
                                        <label className="text-dark fw-6">
                                            Latitude<span className="required"></span>
                                        </label>
                                        <TextField
                                            name="latitude"
                                            error={
                                                errors.latitude &&
                                                (errors.latitude.type === "required" ||
                                                    errors.latitude.type === "maxLength")
                                            }
                                            fullWidth
                                            type="text"
                                            className="form-control"
                                            //   inputProps={{ maxLength: 200 }}
                                            inputRef={register({
                                                required: true, validate: {
                                                    isNumber: (value) => isNumber(value) || 'Trường này chỉ được nhập số',
                                                },
                                            })}
                                            inputProps={{ maxLength: 20 }}
                                            onChange={(e) =>
                                                setValue("latitude", e.target.value)
                                            }
                                        />
                                        {errors.latitude && errors.latitude.type === "required" && (
                                            <span className="error">Trường này là bắt buộc</span>
                                        )}
                                        {errors.latitude && (errors.latitude.type === "isNumber") && (
                                            <span className="error">{errors.latitude.message}</span>
                                        )}
                                    </div>
                                    <div className="col-md-4">
                                        <label className="text-dark fw-6">
                                            Longitude<span className="required"></span>
                                        </label>
                                        <TextField
                                            name="longitude"
                                            error={
                                                errors.longitude &&
                                                (errors.longitude.type === "required" ||
                                                    errors.longitude.type === "maxLength")
                                            }
                                            fullWidth
                                            type="text"
                                            className="form-control"
                                            //   inputProps={{ maxLength: 200 }}
                                            inputRef={register({
                                                required: true, validate: {
                                                    isNumber: (value) => isNumber(value) || 'Trường này chỉ được nhập số',
                                                },
                                            })}
                                            inputProps={{ maxLength: 20 }}
                                            onChange={(e) =>
                                                setValue("longitude", e.target.value)
                                            }
                                        />
                                        {errors.longitude && errors.longitude.type === "required" && (
                                            <span className="error">Trường này là bắt buộc</span>
                                        )}
                                        {errors.longitude && (errors.longitude.type === "isNumber") && (
                                            <span className="error">{errors.longitude.message}</span>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <div className="row">
                                    <div className="col-12">
                                        <label className="text-dark fw-6">
                                            Ảnh biến động<span className="required"></span>
                                        </label>
                                        <DropzoneArea
                                            filesLimit={99}
                                            acceptedFiles={['image/*']}
                                            maxFileSize={50000000000}
                                            onChange={(files) => setGallery(files)}
                                            showFileNames={true}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </DialogContent>
                    <DialogActions className="border-top">
                        <Button
                            type="submit"
                            onClick={onClose}
                            variant="contained"
                            startIcon={<CloseIcon />}
                        >
                            Hủy
                        </Button>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            startIcon={<SaveIcon />}
                        >
                            Lưu
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>

        </>
    )
}

const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(AddReflect);